import { useState } from "react";
// Mui
import {
  Button,
  TextField,
  Alert,
  CircularProgress,
  Typography,
  Box,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useForm, Controller } from "react-hook-form";

// Api
import apiChangePassword from "./api/changePassword";
import { toast } from "react-toastify";

const schema = yup.object({
  password: yup.string().required("Введите пароль"),
  newPassword: yup
    .string()
    .required("Введите пароль")
    .min(8, "Мин. 8 символов")
    .max(30, "Макс. 30 символов")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])/,
      "Пароль должен содержать буквы и цифры"
    ),
  confirmPassword: yup
    .string()
    .required("Повторите пароль")
    .min(8, "Мин. 8 символов")
    .max(30, "Макс. 30 символов")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])/,
      "Пароль должен содержать буквы и цифры"
    )
    .oneOf([yup.ref("newPassword"), null], "Пароли не совпадают"),
});

function ChangePassword() {
  let [isSubmit, setIsSubmit] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((data) => {
    setIsSubmit(true);
    apiChangePassword({
      password: data.password,
      newPassword: data.newPassword,
    })
      .then(() => {
        toast.success("Пароль изменен");
        reset();
      })
      .catch(() => {})
      .finally(() => setIsSubmit(false));
  });

  return (
    <Box>
      <CardHeader
        sx={{ pb: 0 }}
        title={<Typography variant="h6">Пароль</Typography>}
      />

      <CardContent>
        <form onSubmit={onSubmit}>
          <Controller
            name={"password"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputProps={{
                  disableUnderline: true,
                }}
                variant="filled"
                sx={{ mb: 2 }}
                color="secondary"
                fullWidth
                label="Текущий пароль"
                id="password"
                type="password"
                error={Boolean(errors.password?.message)}
                {...register("password")}
                helperText={errors.password?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name={"newPassword"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputProps={{
                  disableUnderline: true,
                }}
                variant="filled"
                sx={{ mb: 2 }}
                color="secondary"
                fullWidth
                label="Новый пароль"
                id="newPassword"
                type="password"
                error={Boolean(errors.newPassword?.message)}
                {...register("newPassword")}
                helperText={errors.newPassword?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name={"confirmPassword"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputProps={{
                  disableUnderline: true,
                }}
                variant="filled"
                sx={{ mb: 2 }}
                color="secondary"
                fullWidth
                label="Повторите новый пароль"
                id="confirmPassword"
                type="password"
                error={Boolean(errors.confirmPassword?.message)}
                {...register("confirmPassword")}
                helperText={errors.confirmPassword?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Button
            disabled={isSubmit}
            startIcon={isSubmit ? <CircularProgress size="20px" /> : null}
            onClick={onSubmit}
            type="submit"
            variant="contained"
          >
            Сохранить
          </Button>
        </form>
      </CardContent>
    </Box>
  );
}

export default ChangePassword;
