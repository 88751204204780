import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Validators
import AddressValidator from "multicoin-address-validator/dist/wallet-address-validator";

import { useForm, Controller } from "react-hook-form";

// Api
import createDeposit from "../api/createDeposit";

import { Icon } from "@iconify/react";

// Hooks
import { useAuth } from "hooks/auth/useAuth";
import { toast } from "react-toastify";
const schema = yup.object({
  address: yup
    .string()
    .required("Введите адрес USDT TRC-20")
    .test("trc-validate", "Введите корректный адрес USDT TRC20", (v) => {
      return AddressValidator.validate(v, "trx");
    }),
  sum: yup
    .number()
    .typeError("Некорректное значение")
    .required("Введите сумму")
    .integer("Число должно быть целым")
    .min(50, "Минимальная сумма 50 USDT"),
});

function DepositDialog({ show, closeHandler, action }) {
  let [isSubmit, setIsSubmit] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      address: "",
      sum: 0,
    },
    resolver: yupResolver(schema),
  });
  // Handlers
  const handleClose = () => {
    closeHandler();
    reset();
  };

  const onSubmit = handleSubmit((d) => {
    setIsSubmit(true);
    let apiData = Object.assign({}, d, { action });
    createDeposit(apiData)
      .then((r) => {
        closeHandler();
        toast.success("Заявка успешно создана");
      })
      .catch((e) => {})
      .finally(() => {
        setIsSubmit(false);
      });
  });

  // Calc
  let title = getTitle(action);
  let descFirst = getFirstDesc(action);
  let descSecond = getSecondDesc(action);
  const { balance } = useAuth();

  return (
    <Dialog
      PaperProps={{
        sx: {
          minWidth: { xs: "calc(100% - 60px)", md: "450px" },
        },
      }}
      maxWidth="sm"
      open={show}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#fff",
        }}
      >
        <Icon icon="radix-icons:cross-2" />
      </IconButton>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <DialogContentText sx={{ mb: 1 }}>{descFirst}</DialogContentText>
          <DialogContentText sx={{ mb: 2 }}>{descSecond}</DialogContentText>
          {action == "withdraw" && (
            <Typography
              variant="h6"
              sx={{ mb: 2 }}
            >{`Ваш баланс: ${balance.balance} USDT`}</Typography>
          )}
          <Controller
            name={"address"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputProps={{
                  disableUnderline: true,
                }}
                // disabled
                variant="filled"
                fullWidth
                label="Ваш адрес USDT TRC-20"
                placeholder="Введите адрес"
                id="address"
                type="text"
                error={Boolean(errors.address?.message)}
                {...register("address")}
                helperText={errors.address?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name={"sum"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                variant="filled"
                fullWidth
                label="Сумма"
                placeholder="Введите сумму"
                id="sum"
                type="number"
                sx={{ my: 2 }}
                error={Boolean(errors.sum?.message)}
                {...register("sum")}
                helperText={errors.sum?.message}
                onChange={onChange}
                value={value}
                InputProps={{
                  // inputMode: "numeric",
                  disableUnderline: true,
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Button fullWidth variant="outlined" onClick={closeHandler}>
                Отменить
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                disabled={isSubmit}
                startIcon={isSubmit ? <CircularProgress /> : null}
                type="submit"
                fullWidth
                onClick={onSubmit}
              >
                Создать заявку
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
}

// Funcs
const getTitle = (action) => {
  switch (action) {
    case "topup":
      return "Пополнить депозит";
    case "withdraw":
      return "Вывести средства";
    default:
      return "Пополнить Депозит";
  }
};

const getFirstDesc = (action) => {
  switch (action) {
    case "topup":
      return "Для того чтобы пополнить депозит, вы должны быть подписаны на Telegram бота. Это необходимо для уведомлений о заявках.";
    case "withdraw":
      return "Для того чтобы вывести средства, вы должны быть подписаны на Telegram бота. Это необходимо для подтверждения вывода.";
    default:
      return "";
  }
};

const getSecondDesc = (action) => {
  switch (action) {
    case "topup":
      return "Введите Ваш адрес USDT TRC-20 кошелька с которого будут переведены средства, и сумму перевода. После чего будет создана заявка, с адресом USDT TRC-20 для перевода.";
    case "withdraw":
      return "Введите Ваш адрес USDT TRC-20 кошелька на который будут переведены средства, и сумму. После чего подтвердите действие в Telegram боте, и мы переведем средства на указанный адрес.";
    default:
      return "";
  }
};

export default DepositDialog;
