import { useEffect, useState } from "react";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  Button,
  CircularProgress,
} from "@mui/material";

import apiGetNotification from "./api/getNotification";
import apiChangeNotification from "./api/changeNotification";
import { toast } from "react-toastify";

// /account/notification

function NotificationPage() {
  let [data, setData] = useState({});
  let [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    apiGetNotification().then((r) => setData(r.data));
  }, []);

  const changeValueHandler = (name, value) => {
    setData((s) => Object.assign({}, s, { [name]: value }));
  };

  const saveHandler = () => {
    setIsSubmit(true);
    apiChangeNotification(data)
      .then((r) => {
        toast.success("Настройки сохранены");
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return (
    <Box>
      <CardHeader
        sx={{ pb: 0 }}
        title={<Typography variant="h6">Email</Typography>}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* <FormControlLabel
              sx={{ mb: 2, display: "block" }}
              control={<Switch checked={data.emailTryLogin} />}
              onChange={(e) =>
                changeValueHandler("emailTryLogin", e.target.checked)
              }
              label="Попытка входа в ЛК"
            /> */}
            <FormControlLabel
              sx={{ mb: 2, display: "block" }}
              control={<Switch checked={data.emailLogin} />}
              onChange={(e) =>
                changeValueHandler("emailLogin", e.target.checked)
              }
              label="Вход в ЛК"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              sx={{ mb: 2, display: "block" }}
              control={<Switch checked={data.emailWithdrawOrder} />}
              onChange={(e) =>
                changeValueHandler("emailWithdrawOrder", e.target.checked)
              }
              label="Создание заявки на вывод средств"
            />
            <FormControlLabel
              sx={{ mb: 2, display: "block" }}
              control={<Switch checked={data.emailSubscribeTelegram} />}
              onChange={(e) =>
                changeValueHandler("emailSubscribeTelegram", e.target.checked)
              }
              label="Подписка на Telegram бота"
            />
          </Grid>
        </Grid>
      </CardContent>

      <CardHeader
        sx={{ pb: 0 }}
        title={<Typography variant="h6">Telegram</Typography>}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* <FormControlLabel
              sx={{ mb: 2, display: "block" }}
              control={<Switch checked={data.telegramTryLogin} />}
              onChange={(e) =>
                changeValueHandler("telegramTryLogin", e.target.checked)
              }
              label="Попытка входа в ЛК"
            /> */}
            <FormControlLabel
              sx={{ mb: 2, display: "block" }}
              control={<Switch checked={data.telegramLogin} />}
              onChange={(e) =>
                changeValueHandler("telegramLogin", e.target.checked)
              }
              label="Вход в ЛК"
            />
          </Grid>
        </Grid>
        <Button
          onClick={saveHandler}
          disabled={isSubmit}
          startIcon={isSubmit ? <CircularProgress size="20px" /> : null}
          sx={{ mt: 2, width: "max-content", px: 4 }}
          variant="contained"
        >
          Сохранить
        </Button>
      </CardContent>
    </Box>
  );
}

export default NotificationPage;
