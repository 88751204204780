// Mui
import { Card, CardContent, Box, Typography, Container } from "@mui/material";

// Page components
import Form from "./components/Form";

function LoginPage() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pt: 6,
      }}
    >
      <Container maxWidth="sm">
        <Card>
          <CardContent>
            <Typography sx={{ mb: 4 }} textAlign="center" variant="h5">
              Авторизация
            </Typography>
            <Form />
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

export { LoginPage };
