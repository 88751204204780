import { useCountdown } from "hooks/order/useCountdown";

function OrderTimer({ expired }) {
  const [seconds] = useCountdown(expired);

  let textTime = getTextTime(seconds);

  if (!textTime) return null;

  return textTime;
}

function getTextTime(seconds) {
  if (seconds <= 0) return null;

  let min = Math.floor(seconds / 60);
  let sec = Math.floor(seconds % 60);

  if (min <= 0 && sec <= 0) return "";

  return `${min} мин. ${sec} сек.`;
}

export default OrderTimer;
