import {
    Box,
    Typography,
    Container,
    Card,
    CardContent,
    IconButton,
    Collapse,
} from "@mui/material";

import config from "config/API";

const DOMAIN = config.DOMAIN;

const HowItWorkPage = () => {
    return (
        <Box sx={{ mt: 4 }}>
            <Container maxWidth="lg">
                <Typography sx={{ mb: 5 }} textAlign="center" variant="h4">
                    Как начать работать?
                </Typography>
                <Container maxWidth="md">
                    <Typography variant="h6" fontWeight="bold" mb={2}>
                        1. Пройдите регистрацию, и активируйте аккаунт через ссылку в письме
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" mb={2}>
                        2. Откройте настройки <Typography component="a" href={`${DOMAIN}/panel/account`} sx={{ color: "primary.main", fontSize: "inherit" }} target="_blank">Личного кабинета</Typography> - "Персональные данные", перейдите по ссылке Telegram и подпишитесь на бота.
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" mb={2}>
                        3.qwe
                    </Typography>
                </Container>
            </Container>
        </Box>
    )
}

export default HowItWorkPage;