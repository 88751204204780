import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  IconButton,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Grid,
} from "@mui/material";

// Modules
import OrderFieldInfo from "components/Order/OrderFieldInfo";

// Components
import OrderTimer from "components/Order/Timer";

// Icons
import { Icon } from "@iconify/react";
// Api
import apiChangeDeposit from "../api/changeDeposit";

function DepositOrderDialog({ show, data, handleClose }) {
  if (!data) return null;

  let view;
  if (data.action == "topup") {
    view = <TopupDeposit handleClose={handleClose} data={data} />;
  } else if (data.action == "withdraw") {
    view = <WithdrawDeposit handleClose={handleClose} data={data} />;
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          minWidth: { xs: "calc(100% - 60px)", md: "450px" },
        },
      }}
      width="md"
      open={show}
      onClose={handleClose}
    >
      {view}
    </Dialog>
  );
}

function TopupDeposit({ data, handleClose }) {
  let view;
  if (data.code == "payment") {
    view = <TopupPaymentView data={data} />;
  } else if (data.code == "confirm-payment") {
    view = <TopupConfirmPaymentView />;
  } else if (data.code == "done") {
    view = <TopupDone data={data} />;
  } else if (data.code == "cancel") {
    view = <TopupCancel data={data} />;
  }

  return (
    <>
      <DialogTitle fontWeight="600">Пополнение депозита</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#fff",
        }}
      >
        <Icon icon="radix-icons:cross-2" />
      </IconButton>

      <DialogContent>{view}</DialogContent>
    </>
  );
}

function WithdrawDeposit({ data, handleClose }) {
  let view;
  if (data.code == "payment") {
    view = <WithdrawPaymentView data={data} />;
  } else if (data.code == "confirm-payment") {
    view = <WithdrawPaymentConfirmView data={data} />;
  } else if (data.code == "done") {
    view = <WithdrawDone data={data} />;
  } else if (data.code == "cancel") {
    view = <WithdrawCancel data={data} />;
  }

  return (
    <>
      <DialogTitle>Вывод средств</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#fff",
        }}
      >
        <Icon icon="radix-icons:cross-2" />
      </IconButton>
      <DialogContent>{view}</DialogContent>
    </>
  );
}

function WithdrawPaymentView({ data, style = {} }) {
  return (
    <Box sx={{ ...style }}>
      <Typography
        sx={{ mb: 2 }}
        fontWeight="bold"
        textAlign="center"
        variant="h6"
      >
        Подтверждение вывода
      </Typography>
      <Typography textAlign="center" variant="body1">
        Зайдите в Телеграм бота и подтвердите вывод средств.
      </Typography>
      <Typography textAlign="center" variant="body1">
        Успейте сделать это в течение <OrderTimer expired={data.expired} />
      </Typography>
    </Box>
  );
}

function WithdrawPaymentConfirmView({ data, style = {} }) {
  return (
    <Box sx={{ ...style }}>
      <Typography fontWeight="bold" textAlign="center" variant="h6">
        Мы проводим перевод средств по вашим реквизитам.
      </Typography>
      <Box
        sx={{
          "& svg": {
            color: (t) => t.palette.primary.main,
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Icon icon="svg-spinners:180-ring" fontSize="52px" />
      </Box>
    </Box>
  );
}

function WithdrawDone({ data, style = {} }) {
  return (
    <Box sx={{ ...style }}>
      <Box
        sx={{
          "& svg": {
            color: (t) => t.palette.success.main,
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon icon="clarity:success-standard-line" fontSize="45px" />
      </Box>

      <Typography
        sx={{ mt: 2 }}
        fontWeight="bold"
        textAlign="center"
        variant="h6"
      >
        Вывод выполнен
      </Typography>
    </Box>
  );
}

function WithdrawCancel({ data, style = {} }) {
  return (
    <Box sx={{ ...style }}>
      <Box
        sx={{
          "& svg": {
            color: (t) => t.palette.error.main,
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon icon="codicon:error" fontSize="45px" />
      </Box>

      <Typography
        sx={{ mt: 2 }}
        fontWeight="bold"
        textAlign="center"
        variant="h6"
      >
        Вывод отклонен
      </Typography>
      <Typography
        sx={{ mt: 2 }}
        // fontWeight="bold"
        textAlign="center"
        variant="body1"
        color="text.secondary"
      >
        Комментарий:{data.comment}
      </Typography>
    </Box>
  );
}

function TopupPaymentView({ data }) {
  let [submit, setSubmit] = useState("");

  const handlePaySubmit = () => {
    setSubmit("confirm");
    apiChangeDeposit({
      _id: data._id,
      action: "confirm-payment",
    }).finally(() => setSubmit(""));
  };
  const handleCancelSubmit = () => {
    setSubmit("cancel");
    apiChangeDeposit({
      _id: data._id,
      action: "cancel",
    }).finally(() => setSubmit(""));
  };

  return (
    <Box>
      <Typography fontWeight="600" textAlign="center" variant="body1">
        Переведите средства по нашим реквизитам указаным ниже, и нажмите{" "}
        <b>"Я оплатил"</b>
      </Typography>
      {/* <OrderFieldInfo
        fieldName="Ваш USDT TRC20 адрес"
        fieldValue={data.address}
      /> */}

      <ServiceRequisites data={data} style={{ mt: 2 }} />
      <Grid sx={{ mt: 2 }} container justifyContent="center" spacing={2}>
        <Grid item xs={6} md={3}>
          <Button
            disabled={submit == "confirm"}
            startIcon={submit == "confirm" && <CircularProgress />}
            variant="contained"
            onClick={handlePaySubmit}
            fullWidth
          >
            Я оплатил
          </Button>
        </Grid>
        <Grid item xs={6} md={3}>
          <Button
            onClick={handleCancelSubmit}
            disabled={submit == "cancel"}
            startIcon={submit == "cancel" && <CircularProgress />}
            variant="outlined"
            color="error"
            fullWidth
          >
            Отменить
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function ServiceRequisites({ data, style = {} }) {
  return (
    <Box sx={{ ...style }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography color="text.hint" variant="body1">
          Время для оплаты:
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          <OrderTimer expired={data.expired} />
        </Typography>
      </Box>

      <OrderFieldInfo
        showCopy
        fieldName="Сумма оплаты"
        fieldValue={data.sum}
        postfixFieldValue="USDT"
      />
      {data.fields.map((f) => {
        return (
          <OrderFieldInfo
            showCopy
            key={f._id}
            fieldName={f.name}
            fieldValue={f.value}
          />
        );
      })}
    </Box>
  );
}

function TopupConfirmPaymentView({ style = {} }) {
  return (
    <Box sx={{ ...style }}>
      <Typography fontWeight="bold" textAlign="center" variant="h6">
        Мы проводим проверку поступления по нашим реквизитам.
      </Typography>
      <Box
        sx={{
          "& svg": {
            color: (t) => t.palette.primary.main,
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Icon icon="svg-spinners:180-ring" fontSize="52px" />
      </Box>
    </Box>
  );
}

function TopupDone({ data, style = {} }) {
  return (
    <Box sx={{ ...style }}>
      <Box
        sx={{
          "& svg": {
            color: (t) => t.palette.success.main,
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon icon="clarity:success-standard-line" fontSize="45px" />
      </Box>

      <Typography
        sx={{ mt: 2 }}
        fontWeight="bold"
        textAlign="center"
        variant="h6"
      >
        Счет пополнен
      </Typography>
    </Box>
  );
}

function TopupCancel({ data, style = {} }) {
  return (
    <Box sx={{ ...style }}>
      <Box
        sx={{
          "& svg": {
            color: (t) => t.palette.error.main,
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon icon="codicon:error" fontSize="45px" />
      </Box>

      <Typography
        sx={{ mt: 2 }}
        fontWeight="bold"
        textAlign="center"
        variant="h6"
      >
        Пополнение отклонено
      </Typography>
      <Typography
        sx={{ mt: 2 }}
        // fontWeight="bold"
        textAlign="center"
        variant="body1"
        color="text.secondary"
      >
        Комментарий:{data.comment}
      </Typography>
    </Box>
  );
}

export default DepositOrderDialog;
