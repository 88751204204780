import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { SxProps } from "@mui/material/styles";

import { Icon } from "@iconify/react";


const EmptyList = ({
  name = "Список пуст",
  style = {},
}) => {
  return (
    <Card
      sx={{
        width: "100%",
        ...style,
      }}
    >
      <CardContent sx={{ p: "16px 32px !important" }}>
        <Typography variant="body2" color="text.hint" textAlign="center">
          {name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default EmptyList;
