import { useState } from "react";
// Mui
import {
  Button,
  TextField,
  CircularProgress,
  Alert,
  Link,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useForm, Controller } from "react-hook-form";

import { Link as RouterLink } from "react-router-dom";

// Api
import recoverEmailApi from "../api/recoverEmail";

const schema = yup.object({
  email: yup.string().email("Не корректный email").required("Введите Email"),
});

function SendEmail({ nextStep }) {
  let [alertError, setAlertError] = useState("");
  let [isSubmit, setIsSubmit] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((data) => {
    setIsSubmit(true);
    recoverEmailApi(data.email)
      .then(() => {
        nextStep(data.email);
      })
      .catch((e) => {
        let message = e?.response?.data?.message;
        if (message) {
          setAlertError(message);
        }
      })
      .finally(() => {
        setIsSubmit(false);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      {alertError && <Alert severity="error">{alertError}</Alert>}
      <Controller
        name={"email"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            InputProps={{
              disableUnderline: true,
            }}
            variant="filled"
            color="secondary"
            fullWidth
            label="Email"
            id="email"
            type="email"
            error={Boolean(errors.email?.message)}
            {...register("email")}
            helperText={errors.email?.message}
            onChange={onChange}
            value={value}
            sx={{ mb: 3 }}
          />
        )}
      />
      <Link component={RouterLink} to="/login">
        Войти
      </Link>
      <Button
        sx={{ mt: 1 }}
        disabled={isSubmit}
        startIcon={isSubmit ? <CircularProgress /> : null}
        onClick={onSubmit}
        type="submit"
        fullWidth
        variant="contained"
      >
        Восстановить
      </Button>
    </form>
  );
}

export default SendEmail;
