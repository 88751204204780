import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  Button,
  Box,
  IconButton,
} from "@mui/material";

import { useForm, Controller } from "react-hook-form";

import InputTextMask from "./InputTextMask";

import apiAddRequisite from "../api/addRequisite";

import { Icon } from "@iconify/react";

// methods
import yupValidate from "./methods/yupValidate";
import { toast } from "react-toastify";

export default function AddRequisiteDialog({ currency, show, closeHandler }) {
  const [isSubmit, setIsSubmit] = useState(false);
  // Handlers

  // Hooks
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
  });
  // Calc
  let currencyId = currency?._id;
  let fields = currency?.fields;

  const onSubmit = handleSubmit((data) => {
    let fieldList = fields.map((f) => {
      return {
        _id: f._id,
        value: data[f._id],
      };
    });
    let requestData = {
      currency: currencyId,
      fields: fieldList,
      name: data.name,
    };
    setIsSubmit(true);
    apiAddRequisite(requestData)
      .then(({ data }) => {
        toast.success("Реквизит успешно добавлен");
        closeHandler();
        clearAll();
      })
      .catch((e) => {})
      .finally(() => {
        setIsSubmit(false);
      });
  });

  const clearAll = () => {
    fields.forEach((f) => {
      setValue(f._id, "");
    });
  };

  const handleClose = () => {
    closeHandler();
    setIsSubmit(false);
    clearAll();
  };

  // max length name and server

  return (
    <Dialog
    PaperProps={{
      sx: {
        minWidth: { xs: "calc(100% - 60px)", md: "450px" },
      },
    }}
      // maxWidth="md"
      open={show}
      onClose={handleClose}
    >
      <DialogTitle>Добавление реквизита</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#fff",
        }}
      >
        <Icon icon="radix-icons:cross-2" />
      </IconButton>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Controller
            name={"name"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value}
                placeholder={"Введите название реквизита"}
                sx={{ my: 1 }}
                onChange={onChange}
                type="text"
                label="Название реквизита"
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                error={Boolean(errors.name?.message)}
                helperText={errors.name?.message}
                {...register("name", {
                  validate: (v) => yupValidate("name", v),
                })}
              />
            )}
          />
          {fields.map((f) => {
            return (
              <Controller
                key={f._id}
                name={f._id}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputTextMask
                    props={{
                      value,
                      variant: "filled",
                      placeholder: f.placeholder,
                      sx: {
                        my: 1,
                      },
                      InputProps: {
                        disableUnderline: true,
                      },
                      onChange,
                      type: f.validation === "number" ? "number" : "text",
                      label: f.name,
                      fullWidth: true,
                      error: Boolean(errors[f._id]?.message),
                      helperText: errors[f._id]?.message,
                      ...register(f._id, {
                        validate: (v) => yupValidate(f.validation, v),
                      }),
                    }}
                    validation={f.validation}
                  />
                )}
              />
            );
          })}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button variant="contained" type="submit">
              Добавить
            </Button>
            <Button variant="outlined" sx={{ ml: 1 }} onClick={handleClose}>
              Отменить
            </Button>
          </Box>
        </DialogContent>
      </form>
    </Dialog>
  );
}
