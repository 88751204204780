import { useState } from "react";
// Mui
import { Card, CardContent, Box, Typography, Container } from "@mui/material";

// Page components
import SendEmail from "./components/SendEmail";
import SendSuccess from "./components/SendSuccess";

import Links from "./components/Links";

function RecoverEmailPage() {
  let [sended, setSended] = useState(false);
  let [email, setEmail] = useState("");

  const changeSendedHandler = (email) => {
    setEmail(email);
    setSended(true);
  };

  let componentView;

  if (sended) {
    componentView = <SendSuccess email={email} />;
  } else {
    componentView = (
      <>
        <SendEmail nextStep={changeSendedHandler} />
      </>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pt: 6,
      }}
    >
      <Container maxWidth="sm">
        <Card>
          <CardContent>
            <Typography sx={{ mb: 4 }} textAlign="center" variant="h5">
              Восстановление пароля
            </Typography>
            {componentView}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

export { RecoverEmailPage };
