import { makeObservable, observable, action, computed } from "mobx";

class GlobalData {
  data = null;

  constructor() {
    makeObservable(this, {
      data: observable,
      setData: action,
      //   getFiatPrices: computed,
    });
  }
  setData(data) {
    this.data = data;
  }
  //   get getFiatPrices() {
  //     return this.fiatPrices;
  //   }
}

const globalDataStore = new GlobalData();

export default globalDataStore;
