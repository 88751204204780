import { Box, Card, Grid } from "@mui/material";

import Contacts from "./Contacts";
import WorkMode from "./WorkMode";

function Security() {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Contacts />
        </Grid>
        <Grid item xs={12} md={6}>
          <WorkMode />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Security;
