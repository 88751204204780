import { useState, useEffect } from "react";

import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
  InputAdornment,
  TextField,
  CircularProgress,
  Switch,
  Divider,
  IconButton,
  Button,
} from "@mui/material";

import { formattedRequisiteValue } from "utils/requisite/formattedValue";

import { toast } from "react-toastify";

import { Icon } from "@iconify/react";

import apiChangeRequisiteCounts from "../../api/changeRequisiteCounts";
import apiChangeRequisiteMain from "../../api/changeRequisiteMain";

function Verified({ data }) {
  let [balance, setBalance] = useState(data.balance);
  let [name, setName] = useState(data.name);

  let isChangeBalance = +balance !== +data.balance;
  let isChangedName = name !== data.name;

  const changeSaveHadnler = (value, action) => {
    apiChangeRequisiteMain({
      _id: data._id,
      value,
      action,
    })
      .then(() => {
        toast.success("Данные сохранены");
      })
      .catch((e) => {})
      .finally(() => {});
  };

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ActiveSwitch
            number={data.number || 1}
            enabled={data.enabled}
            onChange={(v) => changeSaveHadnler(v, "enabled")}
            style={{ mr: 2 }}
          />
          {/* <WorkStatus inWork={data.inWork} /> */}
          <TextField
            variant="filled"
            color="secondary"
            label="Название реквезита"
            type="text"
            size="small"
            sx={{
              flexGrow: 1,
              "& .MuiInputBase-root": {
                pr: 0,
              },
            }}
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            InputProps={{
              disableUnderline: true,
              endAdornment: isChangedName && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(v) => changeSaveHadnler(name, "name")}
                    sx={{ borderRadius: 0 }}
                  >
                    <Icon icon="mingcute:save-line" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <TextField
              label="Баланс"
              type="number"
              size="small"
              variant="filled"
              color="secondary"
              sx={{
                ml: 2,

                "& .MuiInputBase-root": isChangeBalance && {
                  pr: 0,
                },
              }}
              value={balance}
              onChange={(e) => setBalance(e.target.value)}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {isChangeBalance ? (
                      <IconButton
                        onClick={(v) => changeSaveHadnler(balance, "balance")}
                        sx={{ borderRadius: 0 }}
                      >
                        <Icon icon="mingcute:save-line" />
                      </IconButton>
                    ) : (
                      "RUB"
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Grid sx={{ mt: 0 }} container spacing={2}>
          {data.fields.map(({ _id, name, value, validation }) => {
            return (
              <Grid key={_id} item xs={6}>
                <FieldItem name={name} value={value} validation={validation} />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <RequisiteCounts data={data} />
      </CardContent>
    </Card>
  );
}

function RequisiteCounts({ data }) {
  const [counts, setCounts] = useState(getCountObj(data));
  const [isSubmit, setIsSubmit] = useState(false);

  const changeCountHandler = (name, value) => {
    setCounts((prev) => ({ ...prev, [name]: value }));
  };

  let isChanged = getChangedCounts(counts, getCountObj(data));

  const saveHandler = () => {
    setIsSubmit(true);
    apiChangeRequisiteCounts({
      ...objValuesToNumber(counts),
      _id: data._id,
    })
      .then(({}) => {
        toast.success("Данные сохранены");
      })
      .catch((e) => {})
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return (
    <Box>
      <Grid container columns={15} spacing={2}>
        <Grid item xs={7.5} md={3}>
          <CountTextField
            label="Кол-во в день"
            name=""
            count={data.countDay}
            value={counts.maxCountDay}
            setValue={(v) => changeCountHandler("maxCountDay", v)}
          />
        </Grid>
        <Grid item xs={7.5} md={3}>
          <CountTextField
            label="Сумма в день"
            name=""
            count={data.sumDay}
            value={counts.maxSumDay}
            setValue={(v) => changeCountHandler("maxSumDay", v)}
          />
        </Grid>
        <Grid item xs={7.5} md={3}>
          <CountTextField
            label="Кол-во в месяц"
            name=""
            count={data.countMonth}
            value={counts.maxCountMonth}
            setValue={(v) => changeCountHandler("maxCountMonth", v)}
          />
        </Grid>

        <Grid item xs={7.5} md={3}>
          <CountTextField
            label="Сумма в месяц"
            name=""
            count={data.sumMonth}
            value={counts.maxSumMonth}
            setValue={(v) => changeCountHandler("maxSumMonth", v)}
          />
        </Grid>
        <Grid item xs={7.5} md={3}>
          <TextField
            size="small"
            name=""
            label="Макс. баланс"
            variant="filled"
            color="secondary"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingLeft: 0,
              },
            }}
            fullWidth
            value={counts.maxBalance}
            onChange={(e) => changeCountHandler("maxBalance", e.target.value)}
          />
        </Grid>
      </Grid>
      <Button
        onClick={saveHandler}
        sx={{ mt: 1 }}
        disabled={!isChanged}
        startIcon={isSubmit ? <CircularProgress /> : null}
        variant="contained"
      >
        Сохранить
      </Button>
    </Box>
  );
}

function FieldItem({ name, value, validation }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ mr: 1 }} color="text.secondary" variant="body2">
        {name}:
      </Typography>
      <Typography variant="body2">
        {" " + formattedRequisiteValue(validation, value)}
      </Typography>
    </Box>
  );
}

function CountTextField(props) {
  let { label = "", name = "", count = 0, value, setValue } = props;
  return (
    <TextField
      size="small"
      name={name}
      label={label}
      variant="filled"
      color="secondary"
      sx={{
        "& .MuiOutlinedInput-root": {
          paddingLeft: 0,
        },
        "& input[type=number]": {
          "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
      }}
      // required
      type="number"
      fullWidth
      value={value}
      onChange={(e) => setValue(e.target.value)}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment
            sx={{
              width: "100%",
              // backgroundColor: (theme) => theme.palette.divider,
              // height: "100% !important",
              // p: "2px 7px",
              color: (theme) => theme.palette.text.secondary,
              borderTopLeftRadius: (theme) => theme.shape.borderRadius + "px",
              borderBottomLeftRadius: (theme) =>
                theme.shape.borderRadius + "px",
              "& p": {
                textAlign: "right",
                width: "100% !important",
                fontSize: "14px",
              },
            }}
            position="start"
          >
            {count}
          </InputAdornment>
        ),
      }}
    />
  );
}

function WorkStatus({ inWork = false }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        color: inWork ? "success.light" : "error.light",
        minWidth: "fit-content",
        mr: 1,
      }}
    >
      <Icon
        fontSize="24px"
        icon={inWork ? "svg-spinners:180-ring" : "ph:stop-fill"}
      />
      <Typography sx={{ ml: 1 }} variant="body2">
        {inWork ? "В работе" : "Не в работе"}
      </Typography>
    </Box>
  );
}

function ActiveSwitch({ style = {}, enabled, onChange = () => {} }) {
  return (
    <Box sx={{mr: 0.5}}>
      <Switch
        checked={enabled}
        onChange={(e) => onChange(!enabled)}
      />
    </Box>
  );
}

function getCountObj(obj) {
  return {
    maxCountDay: obj.maxCountDay,
    maxSumDay: obj.maxSumDay,
    maxCountMonth: obj.maxCountMonth,
    maxSumMonth: obj.maxSumMonth,
    maxBalance: obj.maxBalance,
  };
}

function objValuesToNumber(obj) {
  for (let prop in obj) {
    obj[prop] = +obj[prop];
  }
  return obj;
}

function getChangedCounts(origObj, savedObj) {
  let isChanged = false;

  for (let prop in origObj) {
    if (+origObj[prop] !== +savedObj[prop]) {
      isChanged = true;
    }
  }

  return isChanged;
}

function checkSumNotNegative(obj) {
  let isNegative = false;

  for (let prop in obj) {
    if (+obj[prop] < 0) {
      isNegative = true;
    }
  }

  return isNegative;
}
export default Verified;
