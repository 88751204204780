import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  Button,
  IconButton,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Avatar,
} from "@mui/material";

import { getFullLinkIcon } from "utils/link";

// Modules
import OrderFieldInfo from "components/Order/OrderFieldInfo";

import moment from "moment";
import "moment/locale/ru";

import { Icon } from "@iconify/react";

// Components
import OrderTimer from "components/Order/Timer";

// Api
import apiChangeReceive from "../api/changeReceive";
import { toast } from "react-toastify";

function OrderReceiveDialog({ show, data, handleClose }) {
  if (!data) return null;

  // ["wait", "done", "cancel"],

  let view;
  if (data.status == "wait") {
    view = <WaitView data={data} />;
  } else if (data.status == "done") {
    view = <DoneView data={data} />;
  } else if (data.status == "cancel") {
    view = <CancelView data={data} />;
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          minWidth: { xs: "calc(100% - 60px)", md: "450px" },
        },
      }}
      maxWidth="md"
      open={show}
      onClose={handleClose}
    >
      <DialogTitle>Получение средств</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#fff",
        }}
      >
        <Icon icon="radix-icons:cross-2" />
      </IconButton>

      <DialogContent>
        {view}
        <Typography
          sx={{ mt: 2 }}
          color="text.secondary"
          textAlign="right"
          variant="body2"
        >
          {moment(data.createdAt).format("HH:mm DD.MM.YYYY")}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

function CancelView({ data }) {
  return (
    <Box>
      <Box
        sx={{
          "& svg": {
            color: (t) => t.palette.error.main,
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon icon="codicon:error" fontSize="45px" />
      </Box>
      <Typography
        sx={{ mt: 2 }}
        fontWeight="bold"
        textAlign="center"
        variant="h6"
      >
        Получение отклонено
      </Typography>
      <Typography
        sx={{ mt: 2 }}
        textAlign="center"
        variant="body1"
        color="text.secondary"
      >
        Комментарий: {data.comment}
      </Typography>
      <OrderFieldInfo
        fieldName="Валюта"
        image={getFullLinkIcon(data?.currency?.logo)}
        fieldValue={data?.currency?.name}
        style={{ mb: 1, mt: 2 }}
      />
      <OrderFieldInfo
        fieldName="Сумма получения"
        fieldValue={data.sum}
        postfixFieldValue="RUB"
      />
    </Box>
  );
}

function DoneView({ data }) {
  return (
    <Box sx={{ min: "100%" }}>
      <Box
        sx={{
          "& svg": {
            color: (t) => t.palette.success.main,
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon icon="clarity:success-standard-line" fontSize="45px" />
      </Box>

      <Typography
        sx={{ mt: 2 }}
        fontWeight="bold"
        textAlign="center"
        variant="h6"
      >
        Средста получены
      </Typography>

      <OrderFieldInfo
        fieldName="Валюта"
        image={getFullLinkIcon(data?.currency?.logo)}
        fieldValue={data?.currency?.name}
        style={{ mb: 1, mt: 2 }}
      />
      <OrderFieldInfo
        fieldName="Сумма получения"
        fieldValue={data.sum}
        postfixFieldValue="RUB"
      />

      {/* recipientFields */}

      <Typography sx={{ mt: 2 }} fontWeight="bold" variant="subtitle1">
        Реквизит получения:
      </Typography>
      {data.recipientFields.map((f) => {
        return <Box key={f._id} sx={{ mb: 1 }}>
          <Typography color="text.hint" variant="subtitle2">{f.name}</Typography>
          <Typography variant="subtitle2">{f.value}</Typography>
        </Box>
      })}
      {
        data.send && <OrderSendView send={data.send} />
      }

      {/* recipientFields */}
    </Box>
  );
}

function OrderSendView({ send }) {
  return <Box>
    <Typography sx={{ mt: 2 }} fontWeight="bold" variant="subtitle1">
      Реквизит перевода:
    </Typography>
    <Box sx={{ mb: 1, display: "flex", alignItems: "center", mt: 1 }}>
      <Avatar sx={{ width: 25, height: 25 }} src={send.logo} />
      {/* <Typography color="text.hint" variant="subtitle2">{send.name}</Typography> */}
      <Typography sx={{ ml: 1 }} variant="subtitle2">{send.name}</Typography>
    </Box>
    <Box sx={{ mb: 1 }}>
      <Typography color="text.hint" variant="subtitle2">Сумма:</Typography>
      <Typography variant="subtitle2">{send.sum} {send.code}</Typography>
    </Box>
    <Box sx={{ mb: 1 }}>
      <Typography color="text.hint" variant="subtitle2">Адрес перевода:</Typography>
      <Typography variant="subtitle2">{send.address}</Typography>
    </Box>
    <Box sx={{ mb: 1 }}>
      <Typography color="text.hint" variant="subtitle2">Транзакции кошелька:</Typography>
      <Typography color="text.primary" component="a" href={send.transaction + send.address} target="_blank" variant="subtitle2">{send.transaction + send.address}</Typography>
    </Box>
  </Box>
}

function WaitView({ data }) {
  let [showConfirmDialog, setShowConfirmDialog] = useState(false);
  let [isSubmit, setIsSubmit] = useState(false);

  const changeHandler = () => {
    setIsSubmit(true);
    apiChangeReceive({
      _id: data._id,
      action: "done",
    })
      .then(() => {
        toast.success("Получение подтверждено!");
      })
      .catch((err) => { })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return (
    <Box>
      <AlertDialog
        isSubmit={isSubmit}
        open={showConfirmDialog}
        sum={data.sum}
        confirmHandler={changeHandler}
        handleClose={() => {
          setShowConfirmDialog(false);
        }}
      />
      <Typography textAlign="center" variant="body2" color="text.secondary">
        Проверьте ваш реквизит ниже, на поступление указанной суммы.
      </Typography>

      <Typography
        sx={{ mb: 2 }}
        textAlign="center"
        variant="body2"
        color="text.secondary"
      >
        После чего, в случае получения нажмите кнопку «Я получил»
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        Времени для подтверждения:{" "}
        <b>
          <OrderTimer expired={data.expired} />
        </b>
      </Typography>
      <OrderFieldInfo
        fieldName="Валюта"
        image={getFullLinkIcon(data?.currency?.logo)}
        fieldValue={data?.currency?.name}
        style={{ mb: 1 }}
      />
      <OrderFieldInfo
        fieldName="Сумма получения"
        fieldValue={data.sum}
        postfixFieldValue="RUB"
      />
      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldsSection title="Куда получаю" fields={data.recipientFields} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldsSection title="От кого" fields={data.senderFields} />
        </Grid>
      </Grid>
      <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => {
            setShowConfirmDialog(true);
          }}
          variant="contained"
        >
          Я получил
        </Button>
      </Box>
    </Box>
  );
}

function AlertDialog({ sum, open, handleClose, isSubmit, confirmHandler }) {
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">Внимание!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Данным действием вы подтверждаете что получили {sum} RUB. Указанная
            сумма будет списана с вашего текущего баланса.
          </DialogContentText>
        </DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
          <Button
            disabled={isSubmit}
            startIcon={isSubmit && <CircularProgress />}
            sx={{ mr: 1 }}
            variant="contained"
            onClick={confirmHandler}
          >
            Подтвердить
          </Button>
          <Button
            onClick={handleClose}
            sx={{ ml: 1 }}
            variant="outlined"
            color="error"
          >
            Отменить
          </Button>
        </Box>
      </Dialog>
    </>
  );
}

function FieldsSection({ title, fields }) {
  return (
    <Box>
      <Typography variant="body1">{title}</Typography>
      <Box sx={{ mt: 1 }}>
        {fields.map((f) => {
          return (
            <Box key={f._id} sx={{ mb: 0.5 }}>
              <Typography color="text.hint" variant="subtitle2">
                {f.name}:
              </Typography>
              <Typography fontWeight="bold" variant="body2">
                {f.value}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

function getStatusTitle(status) {
  switch (status) {
    case "wait":
      return "Ожидание подтверждения";
    case "done":
      return "Средства получены";
    case "cancel":
      return "Получение отменено";
    default:
      return "Неизвестный статус";
  }
}

function getSubtitleText(status) {
  switch (status) {
    case "wait":
      return "";
    case "done":
      return "Вы получили всю необходимую сумму.";
    case "cancel":
      return "Заявка на получение отменена.";
    default:
      return "Неизвестный статус";
  }
}
export default OrderReceiveDialog;
