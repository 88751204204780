import { useState, useEffect } from "react";
// Mui
import {
  Button,
  TextField,
  Alert,
  CircularProgress,
  Link,
  Box,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useForm, Controller } from "react-hook-form";

// Hooks
import useQuery from "hooks/url/useQuery";
import { useSearchParams, Link as RouterLink } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";

// API
import loginApi from "../api/login";
import activationApi from "../api/activation";
import authConfirmApi from "../api/authConfirm";

import confirmRecoverEmail from "../api/confirmRecoverEmail";
import { toast } from "react-toastify";

const schema = yup.object({
  email: yup.string().email("Не корректный email").required("Введите Email"),
  password: yup
    .string()
    .required("Введите пароль")
    .min(8, "Мин. 8 символов")
    .max(30, "Макс. 30 символов"),
});

function Form() {
  let [isSubmit, setIsSubmit] = useState(false);

  // Hooks
  let { login } = useAuth();
  let query = useQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  let activatedHash = query.get("activated");
  let recoverCode = query.get("recover-code");
  let authCode = query.get("authCode");

  useEffect(() => {
    if (authCode) {
      authConfirmApi(authCode)
        .then((r) => {
          login(r.data);
          toast.success("Успешная авторизация!");
          searchParams.delete("authCode");
          setSearchParams(searchParams);
        })
        .catch((e) => {});
    }
    if (activatedHash) {
      activationApi(activatedHash)
        .then(() => {
          toast.success("Аккаунт активирован!");
          searchParams.delete("activated");
          setSearchParams(searchParams);
        })
        .catch((e) => {
          let message = e?.response?.data?.message;
          if (message) {
            toast.error(message);
          }
        });
    }
    if (recoverCode) {
      confirmRecoverEmail(recoverCode)
        .then(() => {
          toast.success("Новый пароль отправлен на Email!");
          searchParams.delete("recover-code");
          setSearchParams(searchParams);
        })
        .catch((e) => {
          let message = e?.response?.data?.message;
          if (message) {
            toast.error(message);
          }
        });
    }
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((d) => {
    setIsSubmit(true);
    loginApi(d)
      .then((r) => {
        let status = r?.data?.status;
        if (status == "confirmLink") {
          toast.success(
            `Письмо с подтверждением отправлено на ${r?.data?.method}!`
          );
        } else {
          login(r.data);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setIsSubmit(false);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Controller
        name={"email"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            InputProps={{
              disableUnderline: true,
            }}
            variant="filled"
            color="secondary"
            sx={{ mb: 1.5 }}
            fullWidth
            label="Email"
            id="email"
            type="email"
            error={Boolean(errors.email?.message)}
            {...register("email")}
            helperText={errors.email?.message}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        name={"password"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            InputProps={{
              disableUnderline: true,
            }}
            variant="filled"
            color="secondary"
            fullWidth
            label="Пароль"
            id="password"
            type="password"
            error={Boolean(errors.password?.message)}
            {...register("password")}
            helperText={errors.password?.message}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Box
        sx={{
          mt: 1,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Link component={RouterLink} to="/register">
          Регистрация
        </Link>
        <Link component={RouterLink} to="/recover-email">
          Забыли пароль
        </Link>
      </Box>

      <Button
        sx={{ mt: 3 }}
        disabled={isSubmit}
        startIcon={isSubmit ? <CircularProgress /> : null}
        onClick={onSubmit}
        type="submit"
        fullWidth
        variant="contained"
      >
        Войти
      </Button>
    </form>
  );
}

export default Form;
