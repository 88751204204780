import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

import socket from "socket";

// Создаем контекст
const AuctionContext = createContext();

// Создаем провайдер контекста
export const AuctionProvider = ({ children }) => {
  const [auctions, setAuctions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);

  const fetchData = async (isAuth) => {
    try {
      const { data } = await axios.get("/auction");
      if (data?.length) {
        setAuctions(data);
        if (isAuth) {
          setShowDialog(true);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    socket.on("order_auction_add", (newAuction) => {
      setAuctions((prev) => [newAuction, ...prev]);
    });
    socket.on("order_auction_upd", (updAuction) => {
      setAuctions((prev) =>
        prev.map((auction) =>
          auction._id === updAuction._id ? updAuction : auction
        )
      );
    });

    socket.on("order_auction_del", (id) => {
      setAuctions((prev) => prev.filter((auction) => auction._id !== id));
    });
    //
    return () => {
      socket.off("order_auction_add");
      socket.off("order_auction_upd");
      socket.off("order_auction_del");
    };
  }, []);

  // Handler
  const closeHandlerDialog = () => {
    setShowDialog(false);
  };

  const showHandlerDialog = () => {
    setShowDialog(true);
  };

  let value = {
    auctions: auctions.filter((a) => a.status === "wait"),
    isLoading,
    showDialog,
    closeHandlerDialog,
    showHandlerDialog,
    fetchData,
  };

  return (
    <AuctionContext.Provider value={value}>{children}</AuctionContext.Provider>
  );
};

// Создаем хук для использования данных из контекста
export const useAuction = () => {
  return useContext(AuctionContext);
};
