import { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  Grid,
  Avatar,
  CardContent,
} from "@mui/material";
import { getFullLinkIcon } from "utils/link";

import axios from "axios";

function TariffPage() {
  const [tariffs, setTariffs] = useState([]);
  useEffect(() => {
    axios.get("/currency").then(({ data }) => {
      setTariffs(data);
    });
  }, []);

  return (
    <Box sx={{ mt: 4 }}>
      <Container maxWidth="lg">
        <Typography textAlign="center" variant="h4">
          Тарифы
        </Typography>
        <Typography
          sx={{ mb: 5 }}
          color="text.secondary"
          textAlign="center"
          variant="body1"
        >
          Указаны проценты прибыли с каждой заявки, за перевод и получение
          средств.
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {tariffs.map((c) => {
              return (
                <Grid item xs={12} md={6} key={c._id}>
                  <CardTariff data={c} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

function CardTariff({ data }) {
  return (
    <Card>
      <CardContent sx={{ p: "12px 18px !important" }}>
        <Grid alignItems="center" container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Avatar
              src={getFullLinkIcon(data.logo)}
              sx={{
                width: "30px",
                height: "30px",
              }}
            />
            <Typography sx={{ ml: 1 }} variant="body1">
              {data.name}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="body2">
              Получение: <b>{data.receivePercent}</b> %
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="body2">
              Перевод: <b>{data.sendPercent}</b> %
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default TariffPage;
