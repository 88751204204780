import { MenuItem, Select, Box, FormControl, InputLabel } from "@mui/material";

import { getFullLinkIcon } from "utils/link";

export default function SelectCurrency({ currencies, value, onChange }) {
  // mui select выбор
  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel id="paymentSystemSelect">Платежная система</InputLabel>
      <Select
        disableUnderline
        labelId="paymentSystemSelect"
        value={value}
        fullWidth
        size="small"
        variant="filled"
        label="Платежная система"
        onChange={(v) => onChange(v.target.value)}
        renderValue={(value) => {
          let cur = currencies.find((b) => b.code === value);
          if (!cur) return null;

          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={getFullLinkIcon(cur.logo)}
                style={{
                  width: "25px",
                  height: "25px",
                }}
                alt={cur.name}
              />
              <span style={{ marginLeft: "10px" }}>{cur.name}</span>
            </Box>
          );
        }}
      >
        {currencies.map((cur) => (
          <MenuItem
            sx={{ display: "flex", alignItems: "center" }}
            key={cur.code}
            value={cur.code}
          >
            <img
              src={getFullLinkIcon(cur.logo)}
              style={{
                width: "25px",
                height: "25px",
              }}
              alt={cur.name}
            />
            <span style={{ marginLeft: "10px" }}>{cur.name}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
