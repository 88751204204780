import React, { useState } from "react";

import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import { observer } from "mobx-react-lite";

import GlobalDataStore from "store/globalData";

import { useAuth } from "hooks/auth/useAuth";

// Modules
import ModalTransferBalance from "./ModalTransferBalance";

function Panel() {
  const [showTransferBalance, setShowTransferBalance] =
    useState(false);

  const USDTValue = GlobalDataStore.data.USDTValue;

  const userData = useAuth();
  const balanceData = userData.balance;
  const balanceUSDT = balanceData.balance;
  const holdBalanceUSDT = balanceData.holdBalance;
  const useHoldBalanceUSDT = balanceData.useHoldBalance;

  //   holdBalance
  const balanceRUB = Math.floor(balanceUSDT * USDTValue);

  return (
    <Box>
      <ModalTransferBalance
        show={showTransferBalance}
        closeHandler={() => {
          setShowTransferBalance(false);
        }}
        balance={balanceUSDT}
        holdBalance={holdBalanceUSDT}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card
            sx={{
              display: "flex",
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} md={5.8}>
                <BalanceCard
                  title="Баланс"
                  sumRUB={balanceRUB}
                  sumUSDT={balanceUSDT}
                  showTransfer
                  transferHandler={() => setShowTransferBalance(true)}
                />
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={12} md={5.8}>
                <BalanceCard
                  title="Страховой баланс"
                  sumUSDT={holdBalanceUSDT}
                  holdUSDT={useHoldBalanceUSDT}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <CourseCard courseUSDT={USDTValue} />
        </Grid>
      </Grid>
    </Box>
  );
}

const BalanceCard= ({
  title = "Баланс",
  sumUSDT = 0,
  sumRUB,
  holdUSDT,
  showTransfer,
  transferHandler,
}) => {
  return (
    <CardContent
      sx={{
        minHeight: "100%",
      }}
    >
      <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">{title}</Typography>
        {showTransfer && (
          <Button onClick={() => transferHandler()} size="small">
            Перевести
          </Button>
        )}
      </Box>

      <Box>
        <Typography component="p" variant="body1">
          <Typography fontWeight="bold" component="span" variant="h6">
            {sumUSDT}
          </Typography>{" "}
          USDT
        </Typography>
      </Box>
      {Number.isFinite(holdUSDT) && (
        <Typography color="warning.light" component="p" variant="body2">
          <Typography fontWeight="bold" component="span" variant="h6">
            {holdUSDT}
          </Typography>{" "}
          USDT{" "}
          <Typography
            textTransform="uppercase"
            color="text.hint"
            component="span"
            variant="body2"
          >
            (в удержании)
          </Typography>
        </Typography>
      )}

      {sumRUB && (
        <Typography
          fontWeight="bold"
          color="text.hint"
          component="span"
          variant="body2"
        >
          ~ {sumRUB} RUB
        </Typography>
      )}
    </CardContent>
  );
};

function CourseCard({ courseUSDT }) {
  return (
    <Card
      sx={{
        minHeight: "100%",
      }}
    >
      <CardContent>
        <Typography sx={{ mb: 2 }} variant="h6">
          Курс USDT
        </Typography>

        <Typography component="p" variant="body1">
          <Typography fontWeight="bold" component="span" variant="h6">
            {courseUSDT}
          </Typography>{" "}
          RUB
        </Typography>
      </CardContent>
    </Card>
  );
}

export default observer(Panel);
