import {
  Card,
  CardContent,
  CardActionArea,
  Grid,
  Box,
  Typography,
  Chip,
} from "@mui/material";

import { Icon } from "@iconify/react";

import moment from "moment";
import "moment/locale/ru";

function DepositCard({ style = {}, data, clickHandler }) {
  // Calc
  let actionName = getNameAction(data.action);
  let chipData = getChipNameAndColor(data.action, data.code);

  // название  сумма дата создания

  return (
    <Card sx={{ ...style }}>
      <CardActionArea onClick={clickHandler}>
        <CardContent>
          <Box
            sx={{
              mb: 3,
              display: "flex",
              alignItems: "center",
              "& svg": {
                color: (t) => t.palette.primary.main,
              },
            }}
          >
            <Icon
              fontSize="22px"
              icon={
                data.action == "topup"
                  ? "uil:money-withdraw"
                  : "uil:money-insert"
              }
            />
            <Typography sx={{ ml: 1 }} variant="body1">
              {actionName}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography color="text.hint" variant="body2">
              Статус:
            </Typography>
            <Chip
              size="small"
              label={chipData.name}
              sx={{
                bgcolor: (t) => t.palette[chipData.color].main,
              }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography color="text.hint" variant="body2">
              Cумма:
            </Typography>
            <Typography fontWeight="600" variant="body2">
              {data.sum} USDT
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography color="text.hint" variant="body2">
              Создано:
            </Typography>
            <Typography fontWeight="600" variant="body2">
              {moment(data.createdAt).format("HH:mm, DD MMMM YYYY")}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function getNameAction(name) {
  switch (name) {
    case "topup":
      return "Пополнение";
    case "withdraw":
      return "Вывод";
    default:
      return "";
  }
}

function getChipNameAndColor(action, code) {
  if (action == "topup") {
    switch (code) {
      case "payment":
        return {
          name: "Оплата",
          color: "warning",
        };
      case "confirm-payment":
        return {
          name: "Подтверждено",
          color: "info",
        };
      case "done":
        return {
          name: "Пополнено",
          color: "success",
        };
      case "cancel":
        return {
          name: "Отклоненно",
          color: "error",
        };
      default:
        return {};
    }
  } else if (action == "withdraw") {
    switch (code) {
      case "payment":
        return {
          name: "Ожидание",
          color: "warning",
        };
      case "confirm-payment":
        return {
          name: "Подтверждено",
          color: "info",
        };
      case "done":
        return {
          name: "Выведено",
          color: "success",
        };
      case "cancel":
        return {
          name: "Отклоненно",
          color: "error",
        };
      default:
        return {};
    }
  }
}

// ["payment", "confirm-payment", "done", "cancel"]

export default DepositCard;
