import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Divider,
} from "@mui/material";

import { Icon } from "@iconify/react";
import { NavLink, useMatch, useNavigate } from "react-router-dom";

import logo from "assets/logo.png";
import constants from "../../../constants/index";

import { Logo } from "components/Logo";

function Menu({ handleClose = () => {} }) {
  return (
    <Card sx={{ ...cardStyle }}>
      <CardContent>
        <Header style={{ mb: 5 }} />
        <Box>
          {navList.map((m, i) => (
            <MenuItem
              handleClose={handleClose}
              key={i}
              {...m}
              style={{ mb: 3 }}
            />
          ))}
          <Divider sx={{ my: 3 }} />
          {publicNavList.map((m, i) => (
            <MenuItem
              handleClose={handleClose}
              key={i}
              {...m}
              style={{ mb: 3 }}
            />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}

function MenuItem({ path, icon, name, style = {}, handleClose }) {
  const match = useMatch(path);
  const navigate = useNavigate();
  const isActive = match ? true : false;

  return (
    <Card
      sx={{
        bgcolor: (t) =>
          isActive ? t.palette.background.second : t.palette.background.paper,
        ...style,
      }}
      onClick={() => {
        navigate(path);
        handleClose();
      }}
    >
      <CardActionArea component={NavLink} path={path}>
        <Box
          sx={{
            display: "flex",
            color: isActive ? "primary.main" : "secondary.main",
            alignItems: "center",
            p: "14px 20px",
          }}
        >
          <Icon icon={icon} fontSize="26px" />
          <Typography
            fontWeight="600"
            sx={{ ml: 1, textDecoration: "none", lineHeight: 1 }}
            variant="body1"
          >
            {name}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
}

function Header({ style = {} }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        pt: 2,
        ...style,
      }}
    >
      <Logo />
    </Box>
  );
}

const publicNavList = [
  {
    name: "Соглашение",
    path: "/agreement",
    icon: "lets-icons:order",
  },
  {
    name: "FAQ",
    path: "/faq",
    icon: "ion:card-outline",
  },
  {
    name: "Тарифы",
    path: "/tariff",
    icon: "solar:course-up-linear",
  },
];

const navList = [
  {
    name: "Панель",
    path: "/panel/dashboard",
    icon: "fluent:panel-left-32-regular",
  },
  {
    name: "Заявки",
    path: "/panel/orders",
    icon: "lets-icons:order",
  },
  {
    name: "Реквизиты",
    path: "/panel/requisites",
    icon: "ion:card-outline",
  },
  {
    name: "Переводы",
    path: "/panel/transfers",
    icon: "mdi:transfer",
  },
  {
    name: "История",
    path: "/panel/history",
    icon: "ic:round-history",
  },
];

const cardStyle = {
  minHeight: "100vh",
  width: "240px",
  borderRadius: 0,
};
export default Menu;
