function formattedRequisiteValue(validation, value) {
  if (validation == "card-number") {
    return value
      .replace(/\s/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();
  } else if (validation == "phone") {
    return value
      .replace(/\s/g, "")
      .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, "($1) $2-$3-$4")
      .trim();
  } else {
    return value;
  }
}

export { formattedRequisiteValue };
