import { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  Grid,
  TextField,
  Divider,
  Typography,
  Chip,
  Box,
  Checkbox,
  Button,
  CircularProgress,
} from "@mui/material";

import moment from "moment";

import { useForm, Controller } from "react-hook-form";

import { Icon } from "@iconify/react";

import yupValidate from "../methods/yupValidate";

import InputTextMask from "../InputTextMask";

import { toast } from "react-toastify";

import apiChangeRequisiteFields from "../../api/changeRequisiteFields";

//         "verified" -

//       not-verified изначально, и после изменения fail-verified
//         "on-verified" - circularProgress и нельзя изменить
//         "fail-verified" - comment, можно изменить
//         "blocked" - заблокировано и нельзя никак изменить

function NotVerified({ data, showSelect, isSelected, setSelected }) {
  const [isSubmit, setIsSubmit] = useState(false);

  const status = data.status;

  const hasComment = !!data.comment;

  // Hooks
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: dataToObj(data),
  });

  let changedValues = watch();

  //   useEffect(()=>{

  // }, [])

  const onSubmit = handleSubmit((d) => {
    let fieldList = data.fields.map((f) => {
      return {
        _id: f._id,
        value: d[f._id],
      };
    });
    let requestData = {
      requisiteId: data._id,
      fields: fieldList,
      name: d.name,
    };
    setIsSubmit(true);
    apiChangeRequisiteFields(requestData)
      .then(({ data }) => {
        toast.success("Реквизит успешно изменен");
        //   closeHandler();
        //   clearAll();
      })
      .catch((e) => {})
      .finally(() => {
        setIsSubmit(false);
      });
  });

  // Calc
  let isChanged = checkObjForChanges(changedValues, dataToObj(data));
  let showSaveButton = status == "fail-verified" || status == "not-verified";

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {status == "not-verified" && showSelect && (
            <Checkbox
              sx={{ p: 0.5 }}
              checked={isSelected}
              onChange={(e) => setSelected(data._id)}
            />
          )}
          <StatusCard status={status} />
        </Box>

        {status == "fail-verified" && hasComment && (
          <Typography variant="body1" color="error">
            Комментарий: {data.comment}.<br />
            Измените данные реквизита, и отправьте на проверку еще раз.
          </Typography>
        )}
      </CardContent>
      <Divider />
      <form onSubmit={onSubmit}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    InputProps={{
                      disableUnderline: true,
                    }}
                    variant="filled"
                    color="secondary"
                    value={value}
                    placeholder={"Введите название реквизита"}
                    onChange={onChange}
                    type="text"
                    size="small"
                    disabled={!showSaveButton}
                    label="Название реквизита"
                    fullWidth
                    error={Boolean(errors.name?.message)}
                    helperText={errors.name?.message}
                    {...register("name", {
                      validate: (v) => yupValidate("name", v),
                    })}
                  />
                )}
              />
            </Grid>
            {data.fields.map((f) => {
              return (
                <Grid item key={f._id} xs={6}>
                  <Controller
                    key={f._id}
                    name={f._id}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputTextMask
                        props={{
                          variant: "filled",
                          color: "secondary",
                          InputProps: {
                            disableUnderline: true,
                          },
                          value,
                          disabled: !showSaveButton,
                          placeholder: f.placeholder,
                          size: "small",
                          onChange,
                          type: f.validation === "number" ? "number" : "text",
                          label: f.name,
                          fullWidth: true,
                          error: Boolean(errors[f._id]?.message),
                          helperText: errors[f._id]?.message,
                          ...register(f._id, {
                            validate: (v) => yupValidate(f.validation, v),
                          }),
                        }}
                        validation={f.validation}
                      />
                    )}
                  />
                </Grid>
              );
            })}
          </Grid>
          {showSaveButton && (
            <Button
              startIcon={isSubmit ? <CircularProgress /> : null}
              type="submit"
              disabled={!isChanged}
              onClick={onSubmit}
              variant="contained"
              sx={{ mt: 2 }}
            >
              Сохранить
            </Button>
          )}
        </CardContent>
      </form>
    </Card>
  );
}

function StatusCard({ status }) {
  let color;
  let label;
  let icon;

  if (status == "not-verified") {
    color = "warning";
    label = "Ожидание отправки на проверку";
    icon = "mingcute:time-line";
  } else if (status == "blocked") {
    color = "error";
    label = "Заблокирован";
    icon = "material-symbols:block";
  } else if (status == "on-verified") {
    color = "info";
    label = "На проверке";
    icon = "svg-spinners:180-ring";
  } else if (status == "fail-verified") {
    color = "error";
    label = "Не прошел проверку";
    icon = "mdi:close-circle";
  }

  // "svg-spinners:180-ring"

  return (
    <Box fullWidth>
      <Chip
        sx={{ width: "100%" }}
        size="large"
        label={label}
        color={color}
        icon={<Icon fontSize="18px" icon={icon} />}
      />
    </Box>
  );
}

const checkObjForChanges = (obj, data) => {
  let isChanged = false;
  for (let key in obj) {
    if (obj[key].trim() !== data[key].trim()) {
      isChanged = true;
    }
  }
  return isChanged;
};

function dataToObj(data) {
  let obj = {
    name: data.name,
  };

  data.fields.forEach((f) => {
    obj[f._id] = f.value;
  });

  return obj;
}

export default NotVerified;
