import { Card, CardContent, Box } from "@mui/material";

// Modules
import NotVerified from "./NotVerified";
import Verified from "./Verified";

function RequisiteCard({
  data,
  showSelect,
  isSelected,
  setSelected,
  style = {},
}) {
  let isActive = data.status == "verified";

  let view = isActive ? (
    <Verified data={data} />
  ) : (
    <NotVerified
      showSelect={showSelect}
      data={data}
      isSelected={isSelected}
      setSelected={setSelected}
    />
  );

  return <Box sx={{ ...style }}>{view}</Box>;
}

export default RequisiteCard;
