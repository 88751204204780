import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  IconButton,
  Collapse,
} from "@mui/material";

import { Icon } from "@iconify/react";

function FaqPage() {
  return (
    <Box sx={{ mt: 4 }}>
      <Container maxWidth="lg">
        <Typography sx={{ mb: 5 }} textAlign="center" variant="h4">
          Вопросы и ответы
        </Typography>
        <Container maxWidth="md">
          {faqList.map((faq, index) => {
            return <FaqCard key={index} data={faq} style={{ mb: 2 }} />;
          })}
        </Container>
      </Container>
    </Box>
  );
}

function FaqCard({ style = {}, data }) {
  let [isOpen, setIsOpen] = useState(false);

  return (
    <Card sx={{ ...style }} onClick={() => setIsOpen(!isOpen)}>
      <CardContent
        sx={{
          p: "12px 16px !important",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography fontWeight="bold" variant="h6">
            {data.question}
          </Typography>
          <IconButton
            sx={{
              p: 0.5,
              "& svg": {
                color: (t) => t.palette.text.secondary,
              },
            }}
          >
            <Icon
              icon={
                isOpen ? "akar-icons:chevron-up" : "akar-icons:chevron-down"
              }
              width="24"
              height="24"
            />
          </IconButton>
        </Box>
        <Collapse in={isOpen}>
          <Typography sx={{ mt: 1 }} variant="body2">
            {data.answer}
          </Typography>
        </Collapse>
      </CardContent>
    </Card>
  );
}

const faqList = [
  {
    question: "Какой у вас адрес?",
    answer:
      "lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
  },
  {
    question: "Какой у вас адрес?",
    answer:
      "lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
  },
  {
    question: "Какой у вас адрес?",
    answer:
      "lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
  },
];

export default FaqPage;
