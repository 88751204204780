import { useState, useEffect, lazy } from "react";
import { Box, Grid, Button } from "@mui/material";

import OrderReceive from "./components/OrderReceive";
import OrderReceiveDialog from "./components/OrderReceiveDialog";
import OrderSendDialog from "./components/OrderSendDialog";

import OrderSend from "./components/OrderSend";

import { useLocation, useNavigate } from "react-router-dom";

// Components
import EmptyList from "components/Common/EmtyList";

// import OrderSend from "./components/OrderSend";

// Api
import apiOrderGetAll from "./api/getAll";

// Socket
import socket from "socket";
import moment from "moment";

import ProblemsOrder from "./ProblemsOrder";

function OrdersPage() {
  let [orders, setOrders] = useState([]);
  let [filter, setFilter] = useState('all');
  let [orderReceiveData, setOrderReceiveData] = useState(null);
  let [showOrderReceiveDialog, setShowOrderReceiveDialog] = useState(false);
  let [orderSendData, setOrderSendData] = useState(null);
  let [showOrderSendDialog, setShowOrderSendDialog] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  let orderSendInfo = location?.state?.orderSendData;

  let existOrdersLastMonth = false;

  if (orders.length && loaded) {
    let lastDate = moment(orders[0].createdAt).valueOf();
    let monthAgo = moment().subtract(-1, "month").valueOf();
    existOrdersLastMonth = monthAgo > lastDate;
  }

  console.log(
    "orderSendData",
    orderSendData,
    "showOrderSendDialog",
    showOrderSendDialog
  );

  useEffect(() => {
    apiOrderGetAll().then((res) => {
      setOrders(res.data);
      setLoaded(true);
    });

    socket.on("order_send_add", (data) => {
      setOrders((prev) => [data, ...prev]);
    });
    socket.on("order_receive_add", (data) => {
      setOrders((prev) => [data, ...prev]);
    });
    // merchant_order-receive_add
    socket.on("order_send_upd", (data) => {
      // setOrderSendData((prev) => (prev?._id == data._id ? data : prev));
      setOrders((prev) => prev.map((d) => (d._id == data._id ? data : d)));
    });
    socket.on("order_receive_upd", (data) => {
      setOrderReceiveData((prev) => (prev?._id == data._id ? data : prev));
      setOrders((prev) => prev.map((d) => (d._id == data._id ? data : d)));
    });

    return () => {
      socket.off("order_send_add");
      socket.off("order_receive_add");
      socket.off("order_send_upd");
      socket.off("order_receive_upd");
    };
  }, []);

  useEffect(() => {
    if (orderSendInfo && !showOrderSendDialog) {
      openOrderSendDialog(orderSendInfo);
      navigate(".", { state: {} });
    }
  }, [orderSendInfo]);

  // Handlers
  const openOrderReceiveDialog = (data) => {
    setOrderReceiveData(data);
    setShowOrderReceiveDialog(true);
  };

  const openOrderSendDialog = (data) => {
    setOrderSendData(data);
    setShowOrderSendDialog(true);
  };

  let filtredOrders = orders;
  if (filter == 'done') {
    filtredOrders = orders.filter((o) => o.status == 'done');
  } else if (filter == 'problem') {
    filtredOrders = orders.filter((o) => o.isProblem);
  } else if (filter == 'cancel') {
    filtredOrders = orders.filter((o) => o.status == 'cancel');
  }


  // "wait", "done", "cancel"


  return (
    <Box>
      <Box>
        <Button
          onClick={() => setFilter('all')}
          sx={{ mr: 1 }} size="small" variant={filter == 'all' ? 'contained' : ''}>Все</Button>
        <Button
          onClick={() => setFilter('done')}
          sx={{ mr: 1 }} size="small" variant={filter == 'done' ? 'contained' : ''}>Выполненные</Button>
        <Button
          onClick={() => setFilter('cancel')}
          sx={{ mr: 1 }} size="small" variant={filter == 'cancel' ? 'contained' : ''}>Отмененные</Button>
        <Button
          onClick={() => setFilter('problem')}
          sx={{ mr: 1 }} size="small" variant={filter == 'problem' ? 'contained' : ''}>Проблемные</Button>
      </Box>
      <ProblemsOrder />
      {!!(loaded && !orders.length) && (
        <EmptyList
          style={{
            mt: 4,
          }}
          name="Ни одной заявки еще не было создано"
        />
      )}

      {existOrdersLastMonth && (
        <EmptyList
          style={{
            mb: 4,
          }}
          name="За последний месяц не было создано ни одной заявки"
        />
      )}

      <OrderReceiveDialog
        show={showOrderReceiveDialog}
        data={orderReceiveData}
        handleClose={() => {
          setShowOrderReceiveDialog(false);
          setOrderReceiveData(null);
        }}
      />
      <OrderSendDialog
        show={showOrderSendDialog}
        data={orderSendData}
        handleClose={() => {
          setShowOrderSendDialog(false);
          setOrderSendData(null);
        }}
      />
      <Grid container spacing={2}>
        {filtredOrders.map((o) => {
          return (
            <Grid item xs={12} key={o._id} md={6} lg={4}>
              <Order
                openOrderSendDialog={openOrderSendDialog}
                openOrderReceiveDialog={openOrderReceiveDialog}
                data={o}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

function Order({ data, openOrderReceiveDialog, openOrderSendDialog }) {
  if (data.isReceive) {
    return (
      <OrderReceive
        clickHandler={() => {
          openOrderReceiveDialog(data);
        }}
        data={data}
      />
    );
  } else if (data.isSend) {
    return (
      <OrderSend
        clickHandler={() => {
          openOrderSendDialog(data);
        }}
        data={data}
      />
    );
  }
}

export { OrdersPage };
