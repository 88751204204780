import { useState, useEffect } from "react";

import { Box, Grid, Tabs, Tab, Card } from "@mui/material";

import { useLocation } from "react-router-dom";

import Security from "./modules/Security";
import Personal from "./modules/Personal";
import Notification from "./modules/Notification/index";

function PersonalPage() {
  const [value, setValue] = useState("personal");

  const location = useLocation();
  let tabInit = location?.state?.tab;

  useEffect(() => {
    if (!tabInit) return;
    setValue(tabInit);
  }, [tabInit]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          // sx: {
          minWidth: { xs: "auto", md: "750px" },
          maxWidth: { xs: "auto", md: "750px" },
          // },
        }}
      >
        <Tabs
          value={value}
          onChange={(e, v) => setValue(v)}
          scrollButtons="auto"
        >
          <Tab label="Персональные данные" value="personal" />
          <Tab label="Безопасность" value="security" />
          {/* <Tab label="Уведомления" value="notify" /> */}
        </Tabs>
        <Box sx={{ mt: 2, width: "100%" }}>
          <Card
            sx={{
              px: 1,
            }}
          >
            {value === "personal" && <Personal />}
            {value === "security" && <Security />}
            {value === "notify" && <Notification />}
          </Card>
        </Box>

        {/* <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <Contacts />
        </Grid>
        <Grid item xs={12} md={5}>
          <WorkMode />
        </Grid>
      </Grid> */}
      </Box>
    </Box>
  );
}

export { PersonalPage };
