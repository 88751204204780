import { Box, Typography, Button } from "@mui/material";

import { useNavigate } from "react-router-dom";

function Registred({ email }) {
  let navigate = useNavigate();

  return (
    <Box>
      <Typography textAlign='center' sx={{ mb: 1 }} variant="h6">
        На адрес <b>{email} </b>отправлено письмо с подтверждением.
      </Typography>
      <Typography textAlign='center' sx={{ mt: 1 }} variant="body1">
        Для завершения регистрация перейдите по ссылке в ней.
      </Typography>
      <Button
        onClick={() => {
          navigate("/login");
        }}
        variant="contained"
        fullWidth
        sx={{ mt: 3 }}
      >
        Продолжить
      </Button>
    </Box>
  );
}

export default Registred;
