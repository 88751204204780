const isDevMode = process.env.NODE_ENV === "development";

const SERVER = isDevMode
  ? "http://localhost:3001"
  : 'https://api.pay2pay.org';

const DOMAIN = isDevMode
  ? "http://localhost:3200"
  : 'https://pay2pay.org';

const config = {
  API: `${SERVER}/client`,
  API_ICONS: `${SERVER}/icons`,
  DOMAIN,
  SERVER,
};

export default config;
