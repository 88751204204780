import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import {
  Box,
  Menu as MuiMenu,
  MenuItem,
  CircularProgress,
  Card,
  Typography,
  Divider,
  Drawer,
  useTheme,
  useMediaQuery,
  IconButton,
  FormControlLabel,
  Switch,
} from "@mui/material";

import { Icon } from "@iconify/react";

import { useNavigate } from "react-router-dom";

// Store
import { observer } from "mobx-react-lite";
import GlobalDataStore from "store/globalData";

import HeaderAuction from "modules/Auction/HeaderAuction";


// Modules
import Menu from "./Menu";
import { UserWorkToggle } from "components/User/WorkToggle";

const ProtectedLayout = () => {
  const { isAuth, loading } = useAuth();

  const theme = useTheme();

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  console.log(isLargeScreen, "isLargeScreen");

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuth) {
    return <Navigate to="/" />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      {isLargeScreen && (
        <Box>
          <Menu />
        </Box>
      )}

      <Box sx={{ flexGrow: 1 }}>
        <TopBar />
        <Box
          sx={{
            p: 2,
            overflowY: "auto",
            maxHeight: "calc(100vh - 70px)",
            boxSizing: "border-box",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

const TopBar = observer(function () {
  const [anchor, setAnchor] = useState(false);
  const { balance } = useAuth();
  const globalData = GlobalDataStore.data;

  const theme = useTheme();

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "70px",
        px: 3,
      }}
    >
      <Drawer anchor={"left"} open={anchor} onClose={() => setAnchor(false)}>
        <Menu
          handleClose={() => {
            setAnchor(false);
          }}
        />
      </Drawer>
      <Box>
        {!isLargeScreen && (
          <IconButton
            onClick={() => {
              setAnchor(true);
            }}
          >
            <Icon fontSize="30px" icon="lucide:menu" />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <HeaderAuction/>
        <UserWorkToggle isSmallLabel labelPlacement="bottom"  />
        <RateCard
          style={{ mr: 1 }}
          isLargeSmall={!isLargeScreen}
          value={globalData.USDTValue}
        />
        {/* <BalanceCard
          isLargeSmall={!isLargeScreen}
          value={balance.balance}
          USDTValue={globalData.USDTValue}
          style={{}}
        /> */}
        <AccountMenu />
      </Box>
    </Box>
  );
});

function AccountMenu() {
  let { logout } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (tab) => {
    navigate("/panel/account", {
      state: { tab: tab },
    });
    handleClose();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IconButton onClick={handleClick} sx={{ ml: 1 }}>
        <Icon fontSize="30px" icon="majesticons:user-box-line" />
      </IconButton>
      <MuiMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleNavigate("personal");
          }}
        >
          Профиль
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleNavigate("security");
          }}
        >
          Безопасность
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            handleNavigate("notify");
          }}
        >
          Уведомление
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            logout();
          }}
        >
          Выход
        </MenuItem>
      </MuiMenu>
    </Box>
  );
}

function RateCard({ value, isLargeSmall = false, style = {} }) {
  return (
    <Card
      sx={{
        p: isLargeSmall ? "6px 12px" : "8px 16px",
        display: "flex",
        alignItems: "center",
        "& svg": {
          color: "primary.main",
        },
        ...style,
      }}
    >
      <Icon fontSize="20px" icon="uil:usd-square" />
      <Typography
        sx={{ ml: 1 }}
        color="primary"
        // variant={isLargeSmall ? "body2" : "body1"}
        variant="body1"
      >
        {value} RUB
      </Typography>
    </Card>
  );
}

function BalanceCard({ value, isLargeSmall = false, style = {}, USDTValue }) {
  let balanceInRub = Math.floor(USDTValue * value);
  balanceInRub = new Intl.NumberFormat("ru-RU", {}).format(balanceInRub);

  return (
    <Card
      sx={{
        p: isLargeSmall ? "6px 12px" : "8px 16px",
        display: "flex",
        alignItems: "center",
        "& svg": {
          color: "primary.main",
        },
        ...style,
      }}
    >
      <Icon fontSize="20px" icon="mdi:account-balance-wallet-outline" />
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Typography sx={{ ml: 1 }} color="primary" variant={"body2"}>
          {value} USDT
        </Typography>
        <Typography sx={{ ml: 1 }} color="primary" variant={"body2"}>
          {balanceInRub} RUB
        </Typography>
      </Box>
      {/*
       */}
    </Card>
  );
}

export default ProtectedLayout;
