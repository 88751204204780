import { Box, FormControlLabel, Switch, Typography } from "@mui/material";

import { useAuth } from "hooks/auth/useAuth";

import apiChangeWorkMode from "./apiChangeWorkMode";

import { toast } from "react-toastify";

function UserWorkToggle({ labelPlacement = "end", isSmallLabel = false }) {
  let { account, setAccountEnabled } = useAuth();

  const changeEnabledHandler = (e) => {
    apiChangeWorkMode({ action: "enabled", enabled: e }).then(() => {
      let text = `Прием заявок ${e ? "включен" : "выключен"}!`;
      toast.success(text);
      setAccountEnabled(e);
    });
  };

  let label = "В работе";
  if (isSmallLabel) {
    label = <Typography variant="body2">{label}</Typography>;
  }

  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={account?.enabled}
            onChange={(e) => changeEnabledHandler(e.target.checked)}
          />
        }
        labelPlacement={labelPlacement}
        label={label}
      />
    </Box>
  );
}

export { UserWorkToggle };
