import { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from "@mui/material";
import moment from "moment";
import "moment/locale/ru";
import { DataGrid } from "@mui/x-data-grid";

import apiGetHistory from "./api/getHistory";

// Components
import EmptyList from "components/Common/EmtyList";

import { Icon } from "@iconify/react";

function HistoryPage() {
  let [history, setHistory] = useState([]);
  let [loading, setLoading] = useState(true);
  useEffect(() => {
    apiGetHistory().then(({ data }) => {
      setHistory(data);
      setLoading(false);
    });
  }, []);

  let list = listFormatingdId(history);

  if (!loading && !list.length) {
    return (
      <EmptyList
        style={{
          mt: 4,
        }}
        name="Начните работу чтобы увидеть историю"
      />
    );
  }

  return (
    <Paper
      sx={{
        borderRadius: "12px",
        overflow: "hidden",
        width: '100%',
        minWidth: '100%'
      }}
    >
      <DataGrid
        sx={{
          maxHeight: "80vh",
          width: '100%'
        }}
        loading={loading}
        rows={list}
        columns={columns}
        disableRowSelectionOnClick
        hideFooter
        disableColumnSelector // отключает стрелки в заголовках столбцов
        disableColumnFilter // отключает фильтры для столбцов
        disableColumnMenu // отключает меню для столбцов
        disableSelectionOnClick // отключает выделение ячейки при клике
        // selectionModel={selectionModel}
        // onSelectionModelChange={handleRowSelection}
      />
    </Paper>
  );
}

function listFormatingdId(history) {
  return history.map((h) => {
    return {
      ...h,
      id: h._id,
    };
  });
}

const columns = [
  {
    field: "dir",
    // sortIcon: '',
    // minWidth: "200px",
    headerName: "Событие",
    flex: 1,
    renderHeader: () => (
      <Typography variant="body1" fontWeight="600">
        Событие
      </Typography>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="body2"
        sx={{
          color: getEventColor(row.action),
          textAlign: "center",
        }}
      >
        {getEventName(row.dir)}
      </Typography>
    ),
  },
  {
    field: "sumUSD",
    headerName: "SUM",
    flex: 1,
    renderHeader: () => (
      <Typography textAlign="center" variant="body1" fontWeight="600">
        Сумма
      </Typography>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography variant="body2">
          {row.sumUSD && `${row.sumUSD} USDT`}
        </Typography>
        <Typography variant="body2">
          {row.sumRUB && `${row.sumRUB} RUB`}
        </Typography>
      </Box>
    ),
  },
  {
    field: "profitUSD",
    headerName: "profit",
    flex: 1,
    renderHeader: () => (
      <Typography textAlign="center" variant="body1" fontWeight="600">
        Прибыль
      </Typography>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography variant="body2">
          {row.profitUSD && `${row.profitUSD} USDT`}
        </Typography>
        <Typography variant="body2">
          {row.profitRUB && `${row.profitRUB} RUB`}
        </Typography>
      </Box>
    ),
  },

  {
    field: "status",
    headerName: "Статус",
    flex: 1,
    renderHeader: () => (
      <Typography variant="body1" fontWeight="600">
        Статус
      </Typography>
    ),
    renderCell: ({ row }) => getIcon(row.status),
  },
  {
    field: "balance",
    headerName: "Баланс",
    flex: 1,
    renderHeader: () => (
      <Typography variant="body1" fontWeight="600">
        Баланс
      </Typography>
    ),
    renderCell: ({ row }) => (
      <Typography variant="body2">{row.balance} USDT</Typography>
    ),
  },
  {
    field: "createdAt",
    headerName: "Дата",
    flex: 1,
    renderHeader: () => (
      <Typography variant="body1" fontWeight="600">
        Дата
      </Typography>
    ),
    renderCell: ({ row }) => (
      <Typography variant="body2">
        {moment(row.createdAt).format("HH:mm DD.MM.YYYY")}{" "}
      </Typography>
    ),
  },
];

function getIcon(status) {
  if (status == "ok") {
    return <Icon fontSize="24px" icon="bi:check-circle-fill" color="#20885F" />;
  } else if (status == "cancel") {
    return <Icon fontSize="24px" icon="maki:cross" color="#D0284B" />;
  }
}

function getEventColor(status) {
  if (status == "add") {
    return "#20885F";
  } else if (status == "sub") {
    return "#D0284B";
  }
}

function getEventName(name) {
  switch (name) {
    case "deposit":
      return "Пополнение";
    case "withdraw":
      return "Вывод";
    case "receive":
      return "Получение";
    case "send":
      return "Перевод";
    case "profit":
      return "Прибыль";
    case "service-profit":
      return "Прибыль сервиса";
    default:
      return "Неизвестно";
  }
}

export { HistoryPage };
