import InputMask from "react-input-mask";
import { TextField } from "@mui/material";

function InputTextMask({ validation, props = {} }) {
  if (validation === "card-number" || validation === "phone") {
    let mask =
      validation === "card-number"
        ? "9999 9999 9999 9999"
        : "+7 (999) 999-99-99";

    return (
      <InputMask mask={mask} maskChar=" " {...props}>
        {(e) => <TextField {...e} disabled={props.disabled}/>}
      </InputMask>
    );
  } else {
    return <TextField {...props} />;
  }
}

export default InputTextMask;
