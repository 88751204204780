import {
  Card,
  CardContent,
  Box,
  Typography,
  CardActionArea,
} from "@mui/material";
import { useAuction } from "context/AuctionContext";
import { keyframes } from "@emotion/react";

import { Icon } from "@iconify/react";

const changeColor = keyframes`
  0% { border-color: transparent; }
  50% { border-color: #F4754F; }
  100% { border-color: transparent; }
`;

export default function HeaderAuction() {
  const { auctions, isLoading, showHandlerDialog } = useAuction();

  if (isLoading || !auctions.length) return null;

  return (
    <Card
      onClick={showHandlerDialog}
      sx={{
        borderColor: "transparent",
        borderWidth: "1px",
        borderStyle: "solid",
        animation: `${changeColor} 1.5s infinite`,
      }}
    >
      <CardActionArea>
        <CardContent
          sx={{
            p: "8px 12px !important",
            display: "flex",
            alignItems: "center",
            "& svg": {
              color: "primary.main",
            },
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              mr: 1,
            }}
            variant="body1"
          >
            {auctions.length}
          </Typography>
          <Icon fontSize="22px" icon="mingcute:auction-fill" />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
