import { Box, Grid, Card } from "@mui/material";

import Auth from "./Auth";
import ChangePassword from "./ChangePassword";

function Security() {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Auth />
        </Grid>
        <Grid item xs={12} md={6}>
          <ChangePassword />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Security;
