import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { useLocalStorage } from "./useLocalStorage";
import axios from "axios";
// Store
import GlobalDataStore from "store/globalData";
import socket, { auth, logout as socketLogout } from "socket";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [account, setAccount] = useState();

  const [loadedAccount, setLoadedAccount] = useState(false);
  const [loadedGlobalData, setLoadedGlobalData] = useState(false);

  // Hooks
  // const navigate = useNavigate();
  // const location = useLocation();

  // Calc
  const isAuth = !!account;
  const balance = account?.balance;
  let isLoaded = loadedAccount && loadedGlobalData;

  // // APi

  const fetchAllData = () => {
    setLoadedGlobalData(false);
    let p1 = axios.get("/global-data");

    Promise.all([p1]).then((values) => {
      let globalData = values[0]?.data;
      GlobalDataStore.setData(globalData);
      setLoadedGlobalData(true);
    });
  };

  const fetchAccountData = (redirect) => {
    let token = localStorage.getItem("access_token");

    if (!token) {
      setLoadedAccount(true);
      setLoadedGlobalData(true);
      return;
    }

    setLoadedAccount(false);
    axios
      .get("/account")
      .then((r) => {
        setAccount(r.data);
        auth();
      })
      .catch(() => {
        localStorage.removeItem("access_token");
      })
      .finally(() => {
        setLoadedAccount(true);
      });
  };

  useEffect(() => {
    fetchAccountData();
    socket.on("balance_upd", (upd) => {
      setAccount((prev) => Object.assign({}, prev, { balance: upd }));
    });
    socket.on("global-data_upd", (upd) => {
      GlobalDataStore.setData(upd);
    });
    return () => {
      socket.off("balance_upd");
      socket.off("global-data_upd");
    };
  }, []);

  useEffect(() => {
    if (isAuth) {
      fetchAllData();
    }
  }, [isAuth]);

  // Handlers
  const login = async (token) => {
    localStorage.setItem("access_token", token);
    fetchAccountData("/panel/dashboard");
  };

  const logout = () => {
    setAccount(null);
    socketLogout();
    localStorage.removeItem("access_token");
  };

  const setAccountEnabled = (enabled) => {
    setAccount((prev) => Object.assign({}, { ...prev, enabled }));
  };

  const value = {
    account,
    isAuth,
    login,
    logout,
    loading: !isLoaded,
    balance,
    setAccountEnabled,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
