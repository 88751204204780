import { useEffect, useState } from "react";
import { Box, Button, Drawer, Chip, IconButton, Typography, TextField } from "@mui/material";
import InputMask from "react-input-mask";
import { Icon } from "@iconify/react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

function ProblemsOrder() {
  const [anchor, setAnchor] = useState(false);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          mb: 2,
        }}
      >
        <Button
          onClick={() => {
            setAnchor(true);
          }}
          variant="secondary"
        >
          Проблемные заявки
        </Button>
      </Box>
      <ProblemsOrderDrawer anchor={anchor} setAnchor={setAnchor} />
    </div>
  );
}

function ProblemsOrderDrawer({ anchor, setAnchor }) {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    axios.get("/problem-order").then(({ data }) => {
      setOrders(data);
    });
  }, []);

  const addOrder = (order) => {
    setOrders([...orders, order]);
  }

  return (
    <Drawer sx={{ "& .MuiDrawer-paper": { minWidth: "400px" } }} anchor={"right"} open={anchor} onClose={() => setAnchor(false)}>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Проблемные заявки</Typography>
          <IconButton
            onClick={() => setAnchor(false)}
            sx={{ ml: 2 }}>
            <Icon fontSize="25px" icon="mingcute:close-line" />
          </IconButton>
        </Box>
      </Box>
      <CreateOrder addOrder={addOrder} />
      <Box sx={{ p: 2 }}>
        {orders.map((order) => (
          <OrderItem order={order} key={order._id} />
        ))}
      </Box>
    </Drawer>
  );
}

function CreateOrder({ addOrder }) {
  let [comment, setComment] = useState("");
  let [sum, setSum] = useState(0);
  let [date, setDate] = useState();
  let [isAdd, setIsAdd] = useState(false);


  const addHandler = () => {
    // check values
    
    if (!comment || !sum) {
      toast.error("Заполните все поля");
      return;
    }
    if (!moment(date, "HH:mm DD.MM.YYYY").isValid()) {
      toast.error("Неверный формат даты");
      return;
    }

    axios.post("/problem-order", {
      comment,
      sum,
      date: moment(date, "HH:mm DD.MM.YYYY").toDate(),
    }).then(({ data }) => {
      toast.success("Заявка создана");
      addOrder(data);
      setIsAdd(false);
    });

    console.log(comment, sum, date);
  }

  let fieldComponent = <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
    <TextField
      variant="filled"
      color="secondary"
      label="Комментарий"
      multiline
      rows={3}
      InputProps={{
        disableUnderline: true,
      }}
      value={comment}
      onChange={(e) => setComment(e.target.value)}
    />
    <TextField
      variant="filled"
      color="secondary"
      label="Сумма"
      InputProps={{
        disableUnderline: true,
      }}
      value={sum}
      onChange={(e) => setSum(e.target.value)}
    />
    {/* ввод текста в формате 2024-01-01 20:00 в виде текстового поля */}
    <InputMask
      mask="99:99 99.99.9999"
      maskChar=""
      value={date}
      onChange={(e) => setDate(e.target.value)}
      variant="filled"
      color="secondary"
      placeholder="20:00 01.01.2024"
      label="Дата"
      InputProps={{
        disableUnderline: true,
      }}
    >
      {(inputProps) => <TextField {...inputProps} />}
    </InputMask>

    {/* <TextField
      
     
      type="datetime-local"
    /> */}
    <Button variant="contained" fullWidth onClick={addHandler}>
      Cоздать
    </Button>
  </Box>


  return <Box sx={{ p: 2 }}>
    {!isAdd ? (
      <Button variant="contained" fullWidth onClick={() => setIsAdd(true)}>
        Добавить заявку
      </Button>
    ) : (
      <div>{fieldComponent}</div>
    )
    }
  </Box >

}

function OrderItem({ order }) {
  return <Box sx={{ p: 2, mb: 2, background: t => t.palette.background.paper, borderRadius: 2 }}>
    <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
      {order.status == 'pending' ? <Chip label="Ожидает" color="warning" /> : <Chip label="Решена" color="success" />}
    </Box>
    <Box>
      <Typography variant="subtitle1">Комментарий:</Typography>
      <Typography variant="subtitle2">{order.comment}</Typography>
    </Box>
    <Box>
      <Typography variant="subtitle1">Сумма:</Typography>
      <Typography variant="subtitle2">{order.sum}</Typography>
    </Box>
    <Box>
      <Typography variant="subtitle1">Дата:</Typography>
      <Typography variant="subtitle2">{moment(order.date).format("HH:mm DD.MM.YYYY")}</Typography>
    </Box>
  </Box>
}

export default ProblemsOrder;
