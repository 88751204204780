import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";

import apiGetCurrencyList from "./api/getCurrencyList";

import { Icon } from "@iconify/react";

// Components
import SelectCurrency from "./components/SelectCurrency";
import AddRequisiteDialog from "./components/AddRequisiteDialog";
import RequisiteCard from "./components/RequisiteCard";
import EmptyList from "components/Common/EmtyList";

// api
import apiGetRequisites from "./api/getAllRequisite";
import apiSendRequisiteVerify from "./api/sendRequisiteVerify";
import { toast } from "react-toastify";

// Socket
import socket from "socket";

//Const
let blockedDesc = `Данные реквизиты нельзя будет использовать в системе.`;
let notVerifiedDesc = `Для того чтобы отправить реквизиты на проверку, нажмите на  Выбрать, после выберете реквиизиты, и нажмите отправить.`;

function RequisitesPage() {
  const [currencies, setCurrencies] = useState([]);
  const [currencyCode, setCurrencyCode] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [requisites, setRequisites] = useState([]);

  useEffect(() => {
    apiGetCurrencyList().then(({ data }) => {
      setCurrencyCode(data[0]?.code);
      setCurrencies(data);
      setLoaded(true);
    });

    socket.on("requisite_add", (data) => {
      setRequisites((prev) => [data, ...prev]);
    });

    socket.on("requisite_upd", (data) => {
      setRequisites((prev) => prev.map((d) => (d._id == data._id ? data : d)));
    });

    return () => {
      socket.off("requisite_add");
      socket.off("requisite_upd");
    };
  }, []);

  useEffect(() => {
    if (!currencyCode) return;
    setLoaded(false);
    apiGetRequisites(currencyCode).then(({ data }) => {
      setRequisites(data);
      setLoaded(true);
    });
  }, [currencyCode]);

  if (!loaded) return null;

  // calc
  let currency = currencies.find((c) => c.code === currencyCode);
  let { verified, notVerified, blocked } = sortRequisites(requisites);

  if (!loaded)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      <AddRequisiteDialog
        currency={currency}
        show={showAddDialog}
        closeHandler={() => setShowAddDialog(false)}
      />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          startIcon={<Icon icon="mdi:add" />}
          onClick={() => setShowAddDialog(true)}
          sx={{ mr: 1, px: 4 }}
          variant="contained"
        >
          Добавить
        </Button>

        <SelectCurrency
          currencies={currencies}
          value={currencyCode}
          onChange={setCurrencyCode}
        />
      </Box>

      {!!(loaded && !requisites.length) && (
        <EmptyList
          style={{
            mt: 4,
          }}
          name="Ни одного реквизита еще не добавлено"
        />
      )}

      <Box sx={{ pt: 3 }}>
        {!!verified.length && (
          <Typography sx={{ mb: 2 }} variant="h6">
            Одобренные
          </Typography>
        )}
        <Grid container spacing={2}>
          {verified.map((r) => (
            <Grid item key={r._id} xs={12} md={6}>
              <RequisiteCard data={r} style={{ mb: 3 }} />
            </Grid>
          ))}
        </Grid>
        {/*  */}
        {!!notVerified.length && <NotVerifiedSector list={notVerified} />}
        {/*  */}
        {!!blocked.length && (
          <Typography sx={{ mb: 2 }} variant="h6">
            Заблокированные
          </Typography>
        )}
        <Grid container spacing={2}>
          {blocked.map((r) => (
            <Grid item key={r._id} xs={12} md={6}>
              <RequisiteCard data={r} style={{ mb: 3 }} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

// "not-verified",
// "on-verified",
// "verified",
// "fail-verified",
// "blocked",

function NotVerifiedSector({ list }) {
  let [isSelect, setIsSelect] = useState(false);
  let [selectedList, setSelectedList] = useState([]);
  let [isSubmit, setIsSubmit] = useState(false);

  if (list.length == 0) return null;

  const cancelSelectHandler = () => {
    setIsSelect(false);
    setSelectedList([]);
  };
  const checkSelectHandler = (id) => {
    return !!selectedList.includes(id);
  };
  const addSelectHandler = (id) => {
    if (checkSelectHandler(id)) {
      setSelectedList(selectedList.filter((i) => i !== id));
    } else {
      setSelectedList([...selectedList, id]);
    }
  };

  const sendRequisiteVerify = () => {
    setIsSubmit(true);
    apiSendRequisiteVerify(selectedList)
      .then(({}) => {
        toast.success("Реквизиты отправлены на проверку!");
        cancelSelectHandler();
      })
      .catch((e) => {})
      .finally(() => {
        setIsSubmit(false);
      });
  };

  let actionBtn;
  if (isSelect) {
    actionBtn = (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {selectedList.length > 0 && (
          <Button
            onClick={sendRequisiteVerify}
            sx={{ mr: 1 }}
            disabled={isSubmit}
            startIcon={isSubmit && <CircularProgress />}
            variant="contained"
          >
            Отправить на проверку
          </Button>
        )}

        <Button onClick={cancelSelectHandler} color="error" variant="outlined">
          Отменить
        </Button>
      </Box>
    );
  } else {
    actionBtn = (
      <Button variant="contained" onClick={() => setIsSelect(true)}>
        Выбрать реквизиты
      </Button>
    );
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Grid sx={{ mb: 2 }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Не проверенные</Typography>
          <Typography variant="body2" color="text.secondary">
            Для того чтобы отправить реквизиты на проверку, нажмите "Выбрать
            реквизиты", после выберете реквиизиты, и нажмите "Отправить на
            проверку.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {actionBtn}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {list.map((r) => (
          <Grid item key={r._id} xs={12} md={6}>
            <RequisiteCard
              data={r}
              style={{}}
              showSelect={isSelect}
              isSelected={checkSelectHandler(r._id)}
              setSelected={addSelectHandler}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function sortRequisites(list) {
  let notVerifiedStatuses = {
    "on-verified": 1,
    "not-verified": 2,
    "fail-verified": 3,
  };
  let verified = list.filter((r) => r.status == "verified");
  let notVerified = list
    .filter(
      (r) =>
        r.status == "not-verified" ||
        r.status == "on-verified" ||
        r.status == "fail-verified"
    )
    .sort(
      (a, b) => notVerifiedStatuses[a.status] - notVerifiedStatuses[b.status]
    );

  let blocked = list.filter((r) => r.status == "blocked");

  return {
    verified,
    notVerified,
    blocked,
  };
}

export { RequisitesPage };
