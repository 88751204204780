// ** React Imports
import { useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";

// ** Third Party Imports
import { useDropzone } from "react-dropzone";

// ** Icon Imports
import { Icon } from "@iconify/react";

// API
import axios from "axios";
import { toast } from "react-toastify";

// ** Third Party Components
// import toast from "react-hot-toast";

const FileUploaderSingle = ({
  sx,
  uploadSuccesHandler = () => {},
  purpose,
}) => {
  // ** State
  const [files, setFiles] = useState([]);

  // ** Hook
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", '.pdf'],
    },
    onDrop: (acceptedFiles) => {
      let file = acceptedFiles[0];

      const formData = new FormData();
      formData.append("image", file);

      let route = "/upload/image";

      axios
        .post(route, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((r) => {
          uploadSuccesHandler(r.data.image);
          toast.success("Файл успешно загружен!");
        })
        .catch((e) => {
          console.log({ e });
        });
      // const formData = new FormData()
      //   formData.append('profileImg', this.state.profileImg)
      setFiles(acceptedFiles.map((file) => Object.assign(file)));
    },
  });

  // axios
  //     .post("/order/send", {
  //       id: auction._id,
  //     })
  //     .then(({ data }) => {
  //       toast.success("Заявка успешно создана");
  //       navigate("/panel/orders", {
  //         state: { orderSendData: data },
  //       });
  //       closeHandler();
  //     })
  //     .catch(() => {})
  //     .finally(() => {
  //       setIsSubmit(false);
  //     });

  const handleLinkClick = (event) => {
    event.preventDefault();
  };

  const img = files.map((file) => (
    <img
      key={file.name}
      alt={file.name}
      height="100%"
      style={{ margin: "0px auto", display: "block" }}
      className="single-file-image"
      src={URL.createObjectURL(file)}
    />
  ));

  let cardStyle = {
    border: "2px dashed rgba(93, 89, 98, 0.22)",
    ...sx,
  };

  return (
    <Card
      {...getRootProps({ className: "dropzone" })}
      sx={files.length ? { height: 150, ...cardStyle } : cardStyle}
    >
      <input {...getInputProps()} />
      {files.length ? (
        img
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 2,
          }}
        >
          <Icon icon="mdi:upload" fontSize="30px" />
          <Box
            sx={{
              ml: 3,
              display: "flex",
              flexDirection: "column",
              textAlign: ["center", "center", "inherit"],
            }}
          >
            <Typography color="textSecondary">
              Перетащите файл сюда или нажмите{" "}
              <Link href="/" onClick={handleLinkClick}>
                «Просмотреть»
              </Link>
              .
            </Typography>
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default FileUploaderSingle;
