import { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Drawer,
  Button,
  IconButton,
  useTheme,
  Card,
  CardActionArea,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import { Outlet } from "react-router-dom";

import { Icon } from "@iconify/react";

import { useMatch, useNavigate, NavLink } from "react-router-dom";
import logo from "assets/logo.png";
import constants from "../../constants/index";

import { useAuth } from "hooks/auth/useAuth";

import { Logo } from "components/Logo";

const HomeLayout = () => {
  return (
    <div>
      <Header />
      <Outlet />
    </div>
  );
};

function Header() {
  const navigate = useNavigate();
  const [anchor, setAnchor] = useState(false);
  let auth = useAuth();

  const enterHandler = () => {
    if (auth.isAuth) {
      navigate("/panel/dashboard");
    } else {
      navigate("/login");
    }
  };

  const theme = useTheme();

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Container>
      <Drawer anchor={"left"} open={anchor} onClose={() => setAnchor(false)}>
        <HomeMobileMenu
          handleClose={() => {
            setAnchor(false);
          }}
        />
      </Drawer>
      {isLargeScreen && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              onClick={() => {
                navigate("/");
              }}
              sx={{
                cursor: "pointer",
              }}
            >
              <Logo />
            </Box>
            <Box sx={{ ml: 4, display: "flex", alignItems: "center" }}>
              {publicNavList.map((m, i) => (
                <MenuItem
                  handleClose={() => {}}
                  key={i}
                  {...m}
                  style={{ mr: 2 }}
                />
              ))}
            </Box>
          </Box>
          <Box>
            <Button onClick={enterHandler} variant="secondary">
              <Icon fontSize="24px" icon="material-symbols:login" />
            </Button>
          </Box>
        </Box>
      )}
      {!isLargeScreen && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 2,
          }}
        >
          <Box>
            <IconButton
              onClick={() => {
                setAnchor(true);
              }}
            >
              <Icon fontSize="30px" icon="lucide:menu" />
            </IconButton>
          </Box>
          <Box
            onClick={() => {
              navigate("/");
            }}
            sx={{
              cursor: "pointer",
            }}
          >
            <Logo />
          </Box>

          {/* <Box sx={{ ml: 4, display: "flex", alignItems: "center" }}>
              {publicNavList.map((m, i) => (
                <MenuItem
                  handleClose={() => {}}
                  key={i}
                  {...m}
                  style={{ mr: 2 }}
                />
              ))}
            </Box> */}
          <Box>
            <Button onClick={enterHandler} variant="secondary">
              <Icon fontSize="24px" icon="material-symbols:login" />
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
}

function MenuItem({ path, icon, name, style = {}, handleClose }) {
  const match = useMatch(path);
  const navigate = useNavigate();
  const isActive = match ? true : false;

  return (
    <Typography
      onClick={() => {
        navigate(path);
      }}
      color={isActive ? "primary.main" : "secondary.main"}
      fontWeight="500"
      sx={{
        ml: 1,
        textDecoration: "none",
        lineHeight: 1,
        fontSize: "18px",
        fontWeight: 500,
        cursor: "pointer",
        ...style,
      }}
    >
      {name}
    </Typography>
  );
}

const publicNavList = [
  {
    name: "Соглашение",
    path: "/agreement",
    icon: "lets-icons:order",
  },
  {
    name: "FAQ",
    path: "/faq",
    icon: "octicon:question-16",
  },
  {
    name: "Тарифы",
    path: "/tariff",
    icon: "solar:course-up-linear",
  },
  {
    name: "Как начать работать?",
    path: "/how-it-works",
    icon: "lsicon:work-order-check-outline",
  },
];

function HomeMobileMenu({ handleClose }) {
  return (
    <Card sx={{ minHeight: "100vh", width: "240px", borderRadius: 0 }}>
      <CardContent>
        <Box
          sx={{
            my: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Logo />
        </Box>
        <Box>
          {publicNavList.map((m, i) => (
            <MobileMenuItem
              handleClose={handleClose}
              key={i}
              {...m}
              style={{ mb: 3 }}
            />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}

function MobileMenuItem({ path, icon, name, style = {}, handleClose }) {
  const match = useMatch(path);
  const navigate = useNavigate();
  const isActive = match ? true : false;

  return (
    <Card
      sx={{
        bgcolor: (t) =>
          isActive ? t.palette.background.second : t.palette.background.paper,
        ...style,
      }}
      onClick={() => {
        navigate(path);
        handleClose();
      }}
    >
      <CardActionArea component={NavLink} path={path}>
        <Box
          sx={{
            display: "flex",
            color: isActive ? "primary.main" : "secondary.main",
            alignItems: "center",
            p: "14px 20px",
          }}
        >
          <Icon icon={icon} fontSize="26px" />
          <Typography
            fontWeight="600"
            sx={{ ml: 1, textDecoration: "none", lineHeight: 1 }}
            variant="body1"
          >
            {name}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default HomeLayout;
