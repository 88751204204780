import { useState } from "react";
// Mui
import {
  Button,
  TextField,
  CircularProgress,
  Link,
  Typography,
  Box,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useForm, Controller } from "react-hook-form";

import { NavLink, Link as RouterLink } from "react-router-dom";

// API
import registerApi from "../api/register";
import { toast } from "react-toastify";

const schema = yup.object({
  email: yup.string().email("Не корректный email").required("Введите Email"),
  password: yup
    .string()
    .required("Введите пароль")
    .min(8, "Мин. 8 символов")
    .max(30, "Макс. 30 символов")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])/,
      "Пароль должен содержать буквы и цифры"
    ),

  confirmPassword: yup
    .string()
    .required("Повторите пароль")
    .min(8, "Мин. 8 символов")
    .max(30, "Макс. 30 символов")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])/,
      "Пароль должен содержать буквы и цифры"
    )
    .oneOf([yup.ref("password"), null], "Пароли не совпадают"),
});

function Form({ setNext }) {
  let [isSubmit, setIsSubmit] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((data) => {
    setIsSubmit(true);
    registerApi(data)
      .then(() => {
        setNext(data.email);
      })
      .catch((e) => {
        // let message = e?.response?.data?.message;
        // if (message) {
        //   toast.error(message);
        // }
      })
      .finally(() => {
        setIsSubmit(false);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Controller
        name={"email"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            InputProps={{
              disableUnderline: true,
            }}
            sx={{ mb: 1.5 }}
            variant="filled"
            color="secondary"
            fullWidth
            label="Email"
            id="email"
            type="email"
            error={Boolean(errors.email?.message)}
            {...register("email")}
            helperText={errors.email?.message}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        name={"password"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            InputProps={{
              disableUnderline: true,
            }}
            sx={{ mb: 1.5 }}
            variant="filled"
            color="secondary"
            fullWidth
            label="Пароль"
            id="password"
            type="password"
            error={Boolean(errors.password?.message)}
            {...register("password")}
            helperText={errors.password?.message}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        name={"confirmPassword"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            InputProps={{
              disableUnderline: true,
            }}
            variant="filled"
            color="secondary"
            fullWidth
            label="Повторите пароль"
            id="confirmPassword"
            type="password"
            error={Boolean(errors.confirmPassword?.message)}
            {...register("confirmPassword")}
            helperText={errors.confirmPassword?.message}
            onChange={onChange}
            value={value}
          />
        )}
      />

      <Box sx={{ mt: 1 }}>
        <Link component={RouterLink} to="/login">
          Вход
        </Link>
      </Box>

      <Button
        disabled={isSubmit}
        startIcon={isSubmit ? <CircularProgress /> : null}
        onClick={onSubmit}
        type="submit"
        variant="contained"
        fullWidth
        sx={{ mt: 3 }}
      >
        Регистрация
      </Button>

      <Typography sx={{ mt: 2 }} variant="body2">
        Нажимая кнопку “Регистрация”, вы автоматически соглашаетесь с условиями{" "}
        <Typography component={NavLink} to="/agreement" color="primary">
          договора оферты
        </Typography>
      </Typography>
    </form>
  );
}

// const styles = {
//   form: {
//     display: "flex",
//     flexDirection: "column",
//   },
// };

export default Form;
