import React from "react";
import { Typography, IconButton, Box } from "@mui/material";

import { Icon } from "@iconify/react";

import { toast } from "react-toastify";

const OrderFieldInfo = ({
  fieldName,
  fieldValue,
  valueSize = "body1",
  postfixFieldValue = "",
  showCopy = false,
  image,
  style = {},
}) => {
  const handleCopyValue = () => {
    if (fieldValue) {

      toast.success("Скопировано!");
      navigator.clipboard.writeText(fieldValue);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...style,
      }}
    >
      <Typography color="text.hint" variant="subtitle2">
        {fieldName}:
      </Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        {!!image && (
          <img
            src={image}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
            }}
          />
        )}
        <Typography variant={valueSize}>
          {fieldValue}
          {` ${postfixFieldValue}`}
        </Typography>
        {showCopy && (
          <IconButton
            sx={{ p: "4px", color: (t) => t.palette.primary.main }}
            onClick={handleCopyValue}
          >
            <Icon icon="ph:copy" />
          </IconButton>
        )}
      </div>
    </Box>
  );
};

export default OrderFieldInfo;
