import { AuthProvider } from "context/AuthContext";

import Router from "./router";
// Libs
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./custom-toastify.css";
import { ThemeProvider } from "@mui/material/styles";
// import {  CssBaseline } from "@mui/material';
import { Box, CssBaseline } from "@mui/material";
import theme from "theme/index";
import DialogAuction from "modules/Auction/DialogAuction";
import { AuctionProvider } from "context/AuctionContext";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          bgcolor: (t) => t.palette.background.default,
          minHeight: "100vh",
        }}
      >
        <div>
          <AuthProvider>
            <AuctionProvider>
              <DialogAuction />
              <Router />
            </AuctionProvider>
          </AuthProvider>
          <ToastContainer
            position="top-right"
            // autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </div>
      </Box>
    </ThemeProvider>
  );
}

export default App;
