
import { luhnAlgoritm } from "utils/card/luhnAlgoritm";

import * as yup from "yup";
const vupValidate = (validation, v) => {
    try {
      switch (validation) {
        case "full-name":
          yup
            .string()
            .required("Введите полное имя")
            .test("fullname-validate", "Введит ФИО через пробел", (v) => {
              let words = v
                .trim()
                .split(" ")
                .filter((w) => w !== "");
              return words.length == 3;
            })
            .validateSync(v);
          break;
        case "card-number":
          yup
            .string()
            .required("Введите номер карты")
            .test("card-validate", "Номер карты некоректен", (v) => {
              let onlynumbers = v.replace(/\D/g, "");
              let fullNums = onlynumbers.length === 16;
              let luhn = luhnAlgoritm(onlynumbers);
  
              return fullNums && luhn;
            })
            .validateSync(v);
          break;
        case "phone":
          yup
            .string()
            .required("Введите номер телефона")
            .test("phone-validate", "Введите корректный номер телефона", (v) => {
              let onlynumbers = v.replace(/\D/g, "");
              return onlynumbers.length === 11;
            })
            .validateSync(v);
          break;
        case "number":
          yup.string().required("Введите номер").validateSync(v);
          break;
        case "name":
          yup
            .string()
            .required("Введите название реквизита")
            .max(50, "Максимальная длина названия 50 символов")
            .validateSync(v);
          break;
        default:
          return "";
      }
    } catch (error) {
      return error?.message;
    }
  };

  export default vupValidate;