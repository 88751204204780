import { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  Typography,
  Box,
  Grid,
  CircularProgress,
  Button,
  DialogContentText,
  DialogActions,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Timer from "components/Order/Timer";

// Socket
import socket from "socket";

// Modules
import OrderFieldInfo from "components/Order/OrderFieldInfo";

import { CopyToClipboard } from "react-copy-to-clipboard";

import moment from "moment";
import "moment/locale/ru";
import { Icon } from "@iconify/react";

// Components
import OrderTimer from "components/Order/Timer";

import ImageUploader from "modules/ImageUploader";

// Api
import apiChangeSend from "../api/changeSend";
import apiGetActiveRequisites from "../api/getActiveRequisites";

import { toast } from "react-toastify";
import { getFullLinkIcon } from "utils/link";

function OrderSendDialog({ show, data, handleClose }) {
  let [order, setOrder] = useState(data);

  const orderSendUpdateHandler = useCallback((upd) => {
    setOrder((prev) => (prev?._id == upd?._id ? upd : prev));
  }, []);

  useEffect(() => {
    socket.on("order_send_upd", orderSendUpdateHandler);
    return () => {
      socket.off("order_send_upd", orderSendUpdateHandler);
    };
  }, []);

  useEffect(() => {
    if (!show) {
      setOrder(null);
      return;
    }

    if (show) {
      if (!order) {
        setOrder(data);
      }
    }
  }, [show]);

  if (!order) return null;

  let view = null;

  if (order.status == "wait") {
    view = <WaitView data={order} />;
  } else if (order.status == "selected") {
    view = <SelectView data={order} />;
  } else if (order.status == "done") {
    view = <DoneView data={order} />;
  } else if (order.status == "cancel") {
    view = <CancelView data={order} />;
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          minWidth: { xs: "calc(100% - 60px)", md: "450px" },
        },
      }}
      maxWidth="md"
      open={show}
      onClose={handleClose}
    >
      <DialogTitle>Перевод средств</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#fff",
        }}
      >
        <Icon icon="radix-icons:cross-2" />
      </IconButton>
      <DialogContent>
        {view}

        <Typography
          sx={{ mt: 2 }}
          color="text.secondary"
          textAlign="right"
          variant="body2"
        >
          {moment(order.createdAt).format("HH:mm DD.MM.YYYY")}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

function SelectView({ data }) {
  let [view, setView] = useState("");
  let [isSubmit, setIsSubmit] = useState(false);
  let [comment, setComment] = useState("");
  let [image, setImage] = useState();

  let senderFields = data.senderFields;

  console.log(senderFields, "senderFields");

  useEffect(() => {
    setImage();
  }, [view]);

  const confirmHandler = () => {
    setIsSubmit(true);
    apiChangeSend({
      _id: data._id,
      status: "done",
      receipt: image,
    })
      .then(() => {
        toast.success("Заявка оплачена");
      })
      .catch(() => {})
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const cancelHandler = () => {
    setIsSubmit(true);
    apiChangeSend({
      _id: data._id,
      status: "cancel",
      comment: comment || "",
    })
      .then(() => {
        toast.success("Заявка отклонена");
      })
      .catch(() => {})
      .finally(() => {
        setIsSubmit(false);
      });
  };

  let main = (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Button
        sx={{ mr: 1 }}
        onClick={() => setView("send")}
        variant="contained"
      >
        Я перевел
      </Button>
      <Button
        sx={{ ml: 1 }}
        onClick={() => setView("cancel")}
        variant="outlined"
        color="error"
      >
        Не смог перевести
      </Button>
    </Box>
  );

  let confirmView = (
    <Box>
      <ImageUploader uploadSuccesHandler={(img) => setImage(img)} />
      <Typography sx={{ mb: 2, mt: 2 }} textAlign="center" variant="body2">
        Данным действием вы <b>подтверждаете</b> что перевели <b>{data.sum}</b>{" "}
        RUB. <br /> Ваш баланс будет пополнен на указанную сумму.
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          sx={{ mr: 1 }}
          onClick={confirmHandler}
          disabled={isSubmit || !image}
          startIcon={isSubmit && <CircularProgress />}
          variant="contained"
        >
          Я подтверждаю
        </Button>
        <Button
          sx={{ ml: 1 }}
          onClick={() => setView("")}
          variant="outlined"
          color="error"
        >
          Отменить
        </Button>
      </Box>
    </Box>
  );

  let cancelView = (
    <Box>
      <Typography sx={{ mb: 2, mt: 2 }} textAlign="center" variant="body2">
        Отправьте сообщение с указанием причины, почему не получилось перевести,{" "}
        <br />
        или нажмите "Отменить", и продолжите перевод.
      </Typography>

      <TextField
        InputProps={{
          disableUnderline: true,
        }}
        variant="filled"
        fullWidth
        label="Комментарий"
        value={comment}
        sx={{ mb: 2 }}
        onChange={(e) => setComment(e.target.value)}
      />

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          sx={{ mr: 1 }}
          onClick={cancelHandler}
          disabled={isSubmit}
          startIcon={isSubmit && <CircularProgress />}
          variant="contained"
        >
          Отклонить
        </Button>
        <Button
          sx={{ ml: 1 }}
          onClick={() => setView("")}
          variant="outlined"
          color="error"
        >
          Отменить
        </Button>
      </Box>
    </Box>
  );

  let viewComponent = main;

  if (view == "send") {
    viewComponent = confirmView;
  } else if (view == "cancel") {
    viewComponent = cancelView;
  }

  return (
    <Box>
      <Typography
        sx={{
          mb: 1,
        }}
        textAlign="center"
        variant="body2"
        color="text.secondary"
      >
        Переведите необходимую суммы, по реквизитам указанным ниже.
      </Typography>

      <Typography
        sx={{
          mb: 2,
        }}
        textAlign="center"
        variant="body2"
        color="text.secondary"
      >
        После чего в случае успеха, нажмите кнопку «Я перевел», <br /> или
        «Отменить» в случае неудачи.
      </Typography>

      <Typography sx={{ mb: 2 }} variant="body1">
        Времени для перевода:{" "}
        <b>
          <OrderTimer expired={data.expired} />
        </b>
      </Typography>

      <OrderFieldInfo
        fieldName="Валюта"
        image={getFullLinkIcon(data?.currency?.logo)}
        fieldValue={data?.currency?.name}
        style={{ mb: 1, mt: 2 }}
      />
      <OrderFieldInfo
        fieldName="Сумма перевода"
        fieldValue={data.sum}
        postfixFieldValue="RUB"
        showCopy
      />
      <Typography sx={{ my: 2 }} variant="body1">
        Реквизит для перевода:
      </Typography>

      <FieldsSection fields={data.fields} />
      <Typography sx={{ my: 2 }} variant="body1">
        Откуда переводите:
      </Typography>

      <FieldsSection hideCopy fields={senderFields} />
      <Box sx={{ mt: 2 }}>{viewComponent}</Box>
    </Box>
  );
}

function WaitView({ data }) {
  let [isSubmit, setIsSubmit] = useState(false);
  let [requisites, setRequisites] = useState([]);
  let [requisiteId, setRequisiteId] = useState("none");

  useEffect(() => {
    apiGetActiveRequisites(data.currency._id).then(({ data }) => {
      setRequisites(data);
      setRequisiteId(data[0]?._id);
    });
  }, []);

  const changeHandler = () => {
    setIsSubmit(true);
    apiChangeSend({
      requisiteId,
      _id: data._id,
      status: "selected",
    })
      .then(() => {
        toast.success("Заявка измененна");
      })
      .catch(() => {})
      .finally(() => {
        setIsSubmit(false);
      });
  };

  let requisiteList = [...requisites];

  return (
    <Box>
      <Typography sx={{ mb: 2 }} variant="body1">
        Времени для перевода:{" "}
        <b>
          <OrderTimer expired={data.expired} />
        </b>
      </Typography>

      <Typography
        sx={{
          mb: 2,
        }}
        textAlign="center"
        variant="body2"
        color="text.secondary"
      >
        Выберите реквизит, с которого будет произведен перевод.
      </Typography>

      <FormControl variant="filled" fullWidth>
        <InputLabel id="requisiteSelect">Реквизит</InputLabel>
        <Select
          disableUnderline
          variant="filled"
          labelId="requisiteSelect"
          value={requisiteId}
          fullWidth
          size="small"
          label="Реквизит"
          onChange={(v) => setRequisiteId(v.target.value)}
          renderValue={(value) => {
            let r = requisiteList.find((b) => b._id === value);

            if (!r) return null;

            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">{r.name}</Typography>
                {r._id != "none" && (
                  <Typography variant="body2" fontWeight="bold">
                    {r.balance} RUB
                  </Typography>
                )}
              </Box>
            );
          }}
        >
          {requisiteList.map((r) => (
            <MenuItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              key={r._id}
              value={r._id}
            >
              <Typography variant="body2">{r.name}</Typography>
              {r._id != "none" && (
                <Typography variant="body2" fontWeight="bold">
                  {r.balance} RUB
                </Typography>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <OrderFieldInfo
        fieldName="Валюта"
        image={getFullLinkIcon(data?.currency?.logo)}
        fieldValue={data?.currency?.name}
        style={{ mb: 1, mt: 2 }}
      />
      <OrderFieldInfo
        fieldName="Сумма перевода"
        fieldValue={data.sum}
        postfixFieldValue="RUB"
        showCopy
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          startIcon={isSubmit && <CircularProgress />}
          disabled={isSubmit}
          onClick={changeHandler}
          sx={{ mt: 2 }}
          variant="contained"
        >
          Продолжить
        </Button>
      </Box>
    </Box>
  );
}

function CancelView({ data }) {
  return (
    <Box>
      <Box
        sx={{
          "& svg": {
            color: (t) => t.palette.error.main,
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon icon="codicon:error" fontSize="45px" />
      </Box>
      <Typography
        sx={{ mt: 2 }}
        fontWeight="bold"
        textAlign="center"
        variant="h6"
      >
        Перевод отклонен
      </Typography>
      <Typography
        sx={{ mt: 2 }}
        textAlign="center"
        variant="body1"
        color="text.secondary"
      >
        Комментарий: {data.comment}
      </Typography>
      <OrderFieldInfo
        fieldName="Валюта"
        image={getFullLinkIcon(data?.currency?.logo)}
        fieldValue={data?.currency?.name}
        style={{ mb: 1, mt: 2 }}
      />
      <OrderFieldInfo
        fieldName="Сумма перевода"
        fieldValue={data.sum}
        postfixFieldValue="RUB"
        showCopy
      />
    </Box>
  );
}

function DoneView({ data }) {
  return (
    <Box>
      <Box
        sx={{
          "& svg": {
            color: (t) => t.palette.success.main,
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon icon="clarity:success-standard-line" fontSize="45px" />
      </Box>
      <Typography
        sx={{ mt: 2 }}
        fontWeight="bold"
        textAlign="center"
        variant="h6"
      >
        Перевод выполнен
      </Typography>
      <OrderFieldInfo
        fieldName="Валюта"
        image={getFullLinkIcon(data?.currency?.logo)}
        fieldValue={data?.currency?.name}
        style={{ mb: 1, mt: 2 }}
      />
      <OrderFieldInfo
        fieldName="Сумма перевода"
        fieldValue={data.sum}
        postfixFieldValue="RUB"
        showCopy
      />
    </Box>
  );
}

function FieldsSection({ fields, hideCopy = false }) {
  const handleCopyValue = (v) => {
    if (v) {
      navigator.clipboard.writeText(v);
      toast.success("Скопировано!");
    }
  };
  return (
    <Box>
      <Box sx={{ mt: 1 }}>
        {fields.map((f) => {
          return (
            <Box key={f._id} sx={{ mb: 0.5 }}>
              <Typography color="text.hint" variant="subtitle2">
                {f.name}:
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography fontWeight="bold" variant="body2">
                  {f.value}
                </Typography>
                {!hideCopy && (
                  <IconButton
                    sx={{ p: "4px", color: (t) => t.palette.primary.main }}
                    onClick={() => handleCopyValue(f.value)}
                  >
                    <Icon icon="ph:copy" />
                  </IconButton>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default OrderSendDialog;
