import { Box, Typography, Container } from "@mui/material";

function AgreementPage() {
  return (
    <Box sx={{ mt: 4 }}>
      <Container maxWidth="lg">
        <Typography textAlign="center" variant="h4">
          Договор оферты
        </Typography>
        <Typography
          sx={{ mb: 5 }}
          color="text.secondary"
          textAlign="center"
          variant="body1"
        >
          Данное соглашение является офертой и определяет условия использования
        </Typography>

        <Typography textAlign="center" variant="body2">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eos
          perferendis numquam quas repellendus dicta! Atque dolore fuga maiores
          consequatur provident quidem, minus facere. Omnis, saepe vel modi
          impedit nisi quod? Lorem ipsum dolor sit amet consectetur adipisicing
          elit. Quia porro nam assumenda sit excepturi rerum quidem harum
          repellat iste sequi praesentium numquam, deserunt a minus odit
          repudiandae, adipisci asperiores nobis? Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Ipsum voluptatibus excepturi omnis
          dignissimos labore quia earum totam, placeat adipisci, assumenda
          ullam, nostrum iusto praesentium reprehenderit autem numquam repellat
          explicabo. Excepturi? Lorem ipsum dolor sit amet consectetur,
          adipisicing elit. Corporis ex inventore esse reiciendis hic ipsam quod
          possimus, voluptas laborum velit provident excepturi distinctio,
          consectetur nesciunt asperiores, saepe obcaecati nemo beatae.
        </Typography>
      </Container>
    </Box>
  );
}

export { AgreementPage };
