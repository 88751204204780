import { useState } from "react";
// Mui
import { Card, CardContent, Box, Typography, Container } from "@mui/material";

// Page components
import Form from "./components/Form";
import Registred from "./components/Registred";

function RegistrationPage() {
  let [isRegistred, setIsRegistred] = useState(false);
  let [email, setEmail] = useState("");

  // Handlers
  const setRegistredHandler = (email) => {
    setEmail(email);
    setIsRegistred(true);
  };

  const registerComponent = (
    <>
      <Typography sx={{ mb: 4 }} textAlign="center" variant="h5">
        Регистрация
      </Typography>
      <Form setNext={setRegistredHandler} />
    </>
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pt: 6,
      }}
    >
      <Container maxWidth="sm">
        <Card maxWidth="sm">
          <CardContent>
            {isRegistred ? <Registred email={email} /> : registerComponent}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

export { RegistrationPage };
