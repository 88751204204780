import { Chip } from "@mui/material";

function CustomChip({ size = "medium", action, status }) {
  let data = getActionData(action, status);

  return <Chip size={size} label={data.name} color={data.color} />;
}

function getActionData(action, status) {
  if (action == "receive") {
    switch (status) {
      case "wait":
        return {
          name: "Ожидание",
          color: "warning",
        };
      case "done":
        return {
          name: "Получено",
          color: "success",
        };
      case "cancel":
        return {
          name: "Отменено",
          color: "error",
        };
      default:
        return {};
    }
  } else if (action == "send") {
    switch (status) {
      case "wait":
      case "selected":
        return {
          name: "Ожидание",
          color: "warning",
        };
      case "done":
        return {
          name: "Переведено",
          color: "success",
        };
      case "cancel":
        return {
          name: "Отменено",
          color: "error",
        };
      default:
        return {};
    }
  }
}

export default CustomChip;
