import { useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  FormControlLabel,
  TextField,
  Switch,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import day from "dayjs";

import { useAuth } from "hooks/auth/useAuth";

import apiChangeWorkMode from "./api/changeWorkMode";
import { toast } from "react-toastify";

import { UserWorkToggle } from "components/User/WorkToggle";

function WorkMode() {
  let { account } = useAuth();
  // let [enabled, setEnabled] = useState(account.enabled);
  let [fromTime, setFromTime] = useState(day(account?.workMode?.from));
  let [fromError, setFromError] = useState("");
  let [toTime, setToTime] = useState(day(account?.workMode?.to));
  let [toError, setToError] = useState("");
  let [enabledTime, setEnabledTime] = useState(account?.workMode?.enabled);

  console.log(account, 'account')

  const changeWorkModeHandler = (e) => {
    const from = day(fromTime).format("HH:mm");
    const to = day(toTime).format("HH:mm");
    const date = new Date();
    date.setHours(Number(from.split(":")[0])); // установка часов из первого времени
    date.setMinutes(Number(from.split(":")[1])); // установка минут из первого времени
    const dateTime1 = date.getTime(); // получение времени в миллисекундах для первого времени

    date.setHours(Number(to.split(":")[0])); // установка часов из второго времени
    date.setMinutes(Number(to.split(":")[1])); // установка минут из второго времени
    const dateTime2 = date.getTime();

    if (dateTime1 > dateTime2) {
      setFromError("От не может быть выше До");
      return;
    }
    apiChangeWorkMode({ action: "workmode", enabled: e, from, to }).then(() => {
      let text = `Прием заявок ${e ? "включен" : "выключен"}!`;
      toast.success(text);

      setEnabledTime(e);
    });
  };

  const timeChangeHandler = (action, value) => {
    if (action == "from") {
      setFromTime(value);
      setFromError("");
    } else if (action == "to") {
      setToTime(value);
      setToError("");
    }
  };

  return (
    <Box>
      <CardHeader
        sx={{ pb: 0 }}
        title={<Typography variant="h6">Режим работы</Typography>}
      />
      <CardContent>
        <UserWorkToggle />

        <Box sx={{ mt: 3 }}>
          <FormControlLabel
            control={
              <Switch
                checked={enabledTime}
                onChange={(e) => changeWorkModeHandler(e.target.checked)}
              />
            }
            label="Период времени в которое вы сможете обрабатывать заявки"
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker", "TimePicker"]}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TimePicker
                    fullWidth
                    ampm={false}
                    label="От"
                    size="small"
                    value={fromTime}
                    disableOpenPicker
                    onChange={(newValue) => timeChangeHandler("from", newValue)}
                    slotProps={{
                      textField: {
                        error: !!fromError,
                        helperText: fromError,
                        fullWidth: true,
                        variant: "filled",
                        color: "secondary",
                        InputProps: {
                          disableUnderline: true,
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    fullWidth
                    ampm={false}
                    label="До"
                    size="small"
                    value={toTime}
                    disableOpenPicker
                    onChange={(newValue) => timeChangeHandler("to", newValue)}
                    slotProps={{
                      textField: {
                        error: !!toError,
                        helperText: toError,
                        fullWidth: true,
                        variant: "filled",
                        color: "secondary",
                        InputProps: {
                          disableUnderline: true,
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </CardContent>
    </Box>
  );
}

export default WorkMode;
