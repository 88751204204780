import { useState, useEffect } from "react";

import { Box, Button, Grid } from "@mui/material";

import { Icon } from "@iconify/react";

//Components
import DepositDialog from "./components/DepositDialog";
import DepositCard from "./components/DepositCard";
import DepositOrderDialog from "./components/DepositOrderDialog";
import EmptyList from "components/Common/EmtyList";

// Api
import apiGetDepositList from "./api/getDepositList";

// Socket
import socket from "socket";

function TransfersPage() {
  let [showOrderDeposit, setShowOrderDeposit] = useState(false);
  let [dataOrderDeposit, setDataOrderDeposit] = useState(null);
  let [showDeposit, setShowDeposit] = useState(false);
  let [depositAction, setDepositAction] = useState(null); // ['topup', 'withdraw'] '
  let [depositList, setDepositList] = useState([]);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    apiGetDepositList().then(({ data }) => {
      setDepositList(data);
      setLoading(false);
    });

    socket.on("deposit_add", (data) => {
      setDepositList((prev) => [data, ...prev]);
    });
    socket.on("deposit_upd", (data) => {
      setDepositList((prev) => prev.map((d) => (d._id == data._id ? data : d)));
      setDataOrderDeposit((prev) => (prev?._id == data._id ? data : prev));
    });

    return () => {
      socket.off("deposit_add");
      socket.off("deposit_upd");
    };
  }, []);

  // Handlers
  const openOrderDeposit = (data) => {
    setDataOrderDeposit(data);
    setShowOrderDeposit(true);
  };

  const closeOrderDeposit = () => {
    setShowOrderDeposit(false);
    setDataOrderDeposit(null);
  };

  const showDepositHandler = (action) => {
    setDepositAction(action);
    setShowDeposit(true);
  };

  if (loading) return null;

  return (
    <Box>
      <DepositOrderDialog
        show={showOrderDeposit}
        handleClose={closeOrderDeposit}
        data={dataOrderDeposit}
      />
      <DepositDialog
        action={depositAction}
        show={showDeposit}
        closeHandler={() => {
          setShowDeposit(false);
        }}
      />
      <Box>
        <Button
          onClick={() => showDepositHandler("topup")}
          startIcon={<Icon icon="streamline:arrow-down-2-solid" />}
          variant="contained"
          sx={{ mr: 1 }}
        >
          Пополнить депозит
        </Button>
        <Button
          onClick={() => showDepositHandler("withdraw")}
          startIcon={<Icon icon="streamline:arrow-up-1-solid" />}
          variant="outlined"
        >
          Вывести средства
        </Button>
      </Box>

      {!!(!loading && !depositList.length) && (
        <EmptyList
          style={{
            mt: 4,
          }}
          name="Пополните депозит для начала работы"
        />
      )}

      <Grid sx={{ my: 2 }} container spacing={2}>
        {depositList.map((d) => (
          <Grid item xs={12} md={6} lg={4} key={d._id}>
            <DepositCard clickHandler={() => openOrderDeposit(d)} data={d} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export { TransfersPage };
