import React, { useState } from "react";

import {
  Box,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";

import { Icon } from "@iconify/react";
import axios from "axios";

import { toast } from "react-toastify";

const ModalTransferBalance = ({
  show,
  closeHandler,
  balance,
  holdBalance,
}) => {
  const [sum, setSum] = useState("");
  const [isHoldTransfer, setIsHoldTransfer] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleClose = () => {
    setSum("0");
    closeHandler();
  };

  const handleChangeSum = (event) => {
    const value = event.target.value;
    // Убираем ведущие нули
    const newValue = value.replace(/^0+(?!$)/, "");
    setSum(newValue);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    axios
      .put("/account/balance/transfer", {
        sum: +sum,
        extract: isHoldTransfer ? "hold" : "balance",
      })
      .then((r) => {
        handleClose();
        toast.success("Перевод произведен!");
      })
      .catch((e) => { })
      .finally(() => {
        setIsSubmit(false);
      });
    // /
  };

  const firstLabel = isHoldTransfer
    ? "Со страхового баланса"
    : "Со платежного баланса";
  const secondLabel = isHoldTransfer
    ? "На платежный баланс"
    : "На страховой баланс";

  return (
    <Dialog
      PaperProps={{
        sx: {
          minWidth: { xs: "calc(100% - 60px)", md: "450px" },
        },
      }}
      maxWidth="sm"
      open={show}
      onClose={handleClose}
    >
      <DialogTitle>Перевод между балансами</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#fff",
        }}
      >
        <Icon icon="radix-icons:cross-2" />
      </IconButton>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <TextField
                InputProps={{
                  disableUnderline: true,
                }}
                variant="filled"
                fullWidth
                label={firstLabel}
                type="number"
                disabled
                value={isHoldTransfer ? holdBalance : balance}
              />
              <TextField
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{ mt: 2 }}
                variant="filled"
                fullWidth
                label={secondLabel}
                id="address"
                type="number"
                value={sum}
                onChange={handleChangeSum}
              />
            </Box>
            <IconButton
              onClick={() => setIsHoldTransfer(!isHoldTransfer)}
              sx={{
                ml: 1,
                "& > svg": {
                  color: (t) => t.palette.primary.main,
                },
              }}
            >
              <Icon fontSize="32px" icon="mingcute:transfer-2-fill" />
            </IconButton>
          </Box>
          <Button
            variant="contained"
            sx={{ mt: 3 }}
            disabled={isSubmit || +sum <= 0}
            startIcon={isSubmit ? <CircularProgress /> : null}
            type="submit"
            fullWidth
            onClick={onSubmit}
          >
            Перевести
          </Button>
        </DialogContent>
      </form>
    </Dialog>
  );
};



export default ModalTransferBalance;
