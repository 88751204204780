import { useState, useEffect } from "react";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

// Libs
import { toast } from "react-toastify";

// Api
import apiGetAccountSecuritySetting from "./api/getAccountSecuritySetting";
import apiChangeAccountSecuritySetting from "./api/changeAccountSecuritySetting";

const authConfirmMethodList = [
  {
    name: "Почта",
    value: "email",
  },
  {
    name: "Телеграм",
    value: "telegram",
  },
];

function Auth() {
  const [setting, setSetting] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    apiGetAccountSecuritySetting().then((r) => {
      setSetting(r.data);
      setLoading(false);
    });
  }, []);

  const changeSettingHandler = (name, value) => {
    setSetting((s) => Object.assign({}, s, { [name]: value }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    apiChangeAccountSecuritySetting({
      _id: setting._id,
      authConfirmMethod: setting.authConfirmMethod,
      confirmLink: setting.confirmLink,
    })
      .then((r) => {
        setSetting(r.data);
        toast.success("Настройки изменены!");
      })
      .catch(() => {})
      .finally(() => {
        setIsSubmit(false);
      });
  };

  if (loading) return null;

  return (
    <Box>
      <CardHeader
        sx={{ pb: 0 }}
        title={<Typography variant="h6">Авторизация</Typography>}
      />

      <CardContent>
        <form onSubmit={submitHandler}>
          <FormControlLabel
            sx={{ mb: 2 }}
            control={<Switch checked={setting.confirmLink} />}
            onChange={(e) =>
              changeSettingHandler("confirmLink", e.target.checked)
            }
            label="Двухфакторная аутентификация"
          />
          <FormControl variant="filled" sx={{ mb: 2 }} fullWidth>
            <InputLabel id="selectAuthConfirmMethod">
              Способ подтверждения
            </InputLabel>
            <Select
              disableUnderline
              color="secondary"
              variant="filled"
              labelId="selectAuthConfirmMethod"
              value={setting.authConfirmMethod}
              label="Способ подтверждения"
              onChange={(e) =>
                changeSettingHandler("authConfirmMethod", e.target.value)
              }
            >
              {authConfirmMethodList.map((i) => (
                <MenuItem key={i.value} value={i.value}>
                  {i.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            disabled={isSubmit}
            startIcon={isSubmit ? <CircularProgress size="20px" /> : null}
            type="submit"
            variant="contained"
          >
            Сохранить
          </Button>
        </form>
      </CardContent>
    </Box>
  );
}

export default Auth;
