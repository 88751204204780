import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  TextField,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useForm, Controller } from "react-hook-form";

// Hooks
import { useAuth } from "hooks/auth/useAuth";

import { Icon } from "@iconify/react";

import apiGetAccountTelegram from "./api/getAccountTelegram";
import apiUnsubscribeAccountTelegram from "./api/unsubscribeAccountTelegram";
import { toast } from "react-toastify";

function Contacts() {
  let [showConnectTelegram, setShowConnectTelegram] = useState(false);
  let [accountTelegramData, setAccountTelegramData] = useState(null);
  let [loading, setLoading] = useState(true);

  // Hooks
  useEffect(() => {
    apiGetAccountTelegram().then((r) => {
      setAccountTelegramData(r.data);
      setLoading(false);
    });
  }, []);
  let { account } = useAuth();

  if (loading) return null;

  // Handlers
  const updAccountTelegramHandler = (upd) => {
    setAccountTelegramData(upd);
  };

  //Calc
  let email = account.email;
  let telegramSubscribed = !!accountTelegramData.nickName;

  return (
    <Box>
      <ChangePhoneDialog
        isSubscribed={telegramSubscribed}
        code={accountTelegramData.linkCode}
        link={accountTelegramData.fullSubscribeLink}
        open={showConnectTelegram}
        handleClose={() => setShowConnectTelegram(false)}
        updateHandler={updAccountTelegramHandler}
      />
      <CardHeader
        sx={{ pb: 0 }}
        title={<Typography variant="h6">Контакты</Typography>}
      />
      <CardContent>
        <TextField
          InputProps={{
            disableUnderline: true,
          }}
          variant="filled"
          fullWidth
          label="Email"
          disabled
          sx={{ mb: 2 }}
          color="secondary"
          type="text"
          value={email}
        />
        <TextField
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <IconButton onClick={() => setShowConnectTelegram(true)}>
                <Icon
                  icon={
                    telegramSubscribed
                      ? "material-symbols:delete"
                      : "basil:add-outline"
                  }
                />
              </IconButton>
            ),
          }}
          color="secondary"
          variant="filled"
          fullWidth
          label="Telegram"
          type="text"
          value={
            telegramSubscribed
              ? "@" + accountTelegramData.nickName
              : "не подписан"
          }
        />
      </CardContent>
    </Box>
  );
}

function ChangePhoneDialog({
  open,
  handleClose,
  code,
  link,
  isSubscribed,
  updateHandler,
}) {
  const [isSubmit, setIsSubmit] = useState(false);

  const submitClickHandler = () => {
    if (!isSubscribed) {
      return window.open(link);
    }

    setIsSubmit(true);
    apiUnsubscribeAccountTelegram()
      .then(({ data }) => {
        updateHandler(data);
        toast.success("Вы отписалисть от Телеграм Бота!");
        handleClose();
      })
      .catch(() => {})
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return (
    <Dialog
    PaperProps={{
      sx: {
        minWidth: { xs: "calc(100% - 60px)", md: "450px" },
      },
    }}
    open={open} onClose={handleClose}>
      <DialogTitle>Телеграм</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#fff",
        }}
      >
        <Icon icon="radix-icons:cross-2" />
      </IconButton>
      <DialogContent>
        <DialogContentText>
          {isSubscribed
            ? "После отписки от бота, вы перестанете получать уведомления"
            : "Чтобы подписаться на Телеграм Бота, перейдите по ссылке с уникальным идентификатором."}
        </DialogContentText>
        {!isSubscribed && (
          <TextField
          InputProps={{
            disableUnderline: true,
          }}
            autoFocus
            variant="filled"
            margin="dense"
            id="code"
            value={code}
            label="Код авторизации"
            type="text"
            fullWidth
          />
        )}
      </DialogContent>
      <DialogActions sx={{
        display: "flex",
        justifyContent: 'center'
      }}>
        <Button variant="outlined" onClick={handleClose}>Отменить</Button>
        <Button
          variant="contained"
          disabled={isSubmit}
          startIcon={isSubmit ? <CircularProgress /> : null}
          onClick={submitClickHandler}
        >
          {isSubscribed ? "Отписаться" : "Подписаться"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Contacts;
