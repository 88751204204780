import { useEffect, useState } from "react";

import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  Button,
  Card,
  CardContent,
} from "@mui/material";

import { useAuction } from "context/AuctionContext";

import { Icon } from "@iconify/react";

import { useNavigate } from "react-router-dom";

// Components
import OrderTimer from "components/Order/Timer";

import { getFullLinkIcon } from "utils/link";

import axios from "axios";
import { toast } from "react-toastify";

export default function DialogAuction() {
  const { auctions, isLoading, showDialog, closeHandlerDialog } = useAuction();

  if (isLoading) return null;

  return (
    <Box>
      <Dialog
        PaperProps={{
          sx: {
            minWidth: { xs: "calc(100% - 60px)", md: "450px" },
          },
        }}
        open={showDialog}
        onClose={closeHandlerDialog}
      >
        <DialogTitle>Аукционы на перевод</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeHandlerDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <Icon icon="radix-icons:cross-2" />
        </IconButton>
        <DialogContent>
          {auctions?.length ? (
            <Content closeHandler={closeHandlerDialog} auctions={auctions} />
          ) : (
            <Typography textAlign="center">Список аукционов пуст</Typography>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}

function Content({ auctions, closeHandler }) {
  const navigate = useNavigate();

  return (
    <Box>
      <Typography sx={{ mb: 2 }} variant="body1">
        Для того чтобы создать заявку на Перевод, выберите аукцион и нажмите
        "Выполнить"
      </Typography>
      {auctions.map((a) => {
        return (
          <AuctionCardItem
            navigate={navigate}
            closeHandler={closeHandler}
            key={a._id}
            auction={a}
            style={{ mb: 2 }}
          />
        );
      })}
    </Box>
  );
}

function AuctionCardItem({ auction, style = {}, navigate, closeHandler }) {
  let [isSubmit, setIsSubmit] = useState(false);

  const createSendOrderHandler = () => {
    setIsSubmit(true);
    axios
      .post("/order/send", {
        id: auction._id,
      })
      .then(({ data }) => {
        toast.success("Заявка успешно создана");
        navigate("/panel/orders", {
          state: { orderSendData: data },
        });
        closeHandler();
      })
      .catch(() => {})
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return (
    <Card sx={{ ...style }}>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "12px 16px !important",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={getFullLinkIcon(auction?.currency?.logo)}
            style={{
              width: "30px",
              height: "30px",
            }}
            alt={auction?.currency?.name}
          />
          <Box sx={{ ml: 1 }}>
            <Typography variant="body1">{auction?.currency?.name}</Typography>
            <Typography fontWeight="bold" variant="body1">
              {new Intl.NumberFormat("ru-RU").format(auction?.sum)} RUB
            </Typography>
            <OrderTimer expired={auction.expired} />
          </Box>
        </Box>
        <Button
          onClick={createSendOrderHandler}
          disabled={isSubmit}
          variant="contained"
        >
          Выполнить
        </Button>
      </CardContent>
    </Card>
  );
}
