import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "5px"
        },
        "*::-webkit-scrollbar-track": {
          background: "#E4EFEF"
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#1D388F61",
          borderRadius: "2px"
        }
      }
    }
  },
  palette: {
    mode: "dark",
    text: {
      primary: "#FFFFFF",
      secondary: "#7A7A7A",
      disabled: "#C2C2C2",
      hint: "#999999",
    },
    background: {
      paper: "#141414",
      default: "#0A0A0A",
      second: "#1F1F1F",
    },
    primary: {
      main: "#F4754F",
      light: "#FCDCD3",
      dark: "#CF3519",
      contrastText: "#fff",
    },

    secondary: {
      main: "#edf2ff",
    },
    success: {
      main: "#20885F",
      light: "#81C5A2",
      // contrastText: "#fff",
    },
    error: {
      main: "#D0284B",
      light: "#F78794",
      // contrastText: "#fff",
    },
    warning: {
      main: "#DD8913",
      light: "#FFCC76",
      // contrastText: "#fff",
    },
    info: {
      main: "#0498DC",
      light: "#6ADAFF",
      // contrastText: "#fff",
    },
    divider: "#333",
    // common: {
    //     black: '#fff',
    //     white: '#fff'
    // }
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "12px",
          //   bgc
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: "none",
          // Ваши стили для Tab
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 6,
        },
        switchBase: {
          "&.Mui-checked": {
            transform: "translateX(20px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
              opacity: 1,
            },
          },
        },
        thumb: {
          boxShadow: "none",
        },
        track: {
          opacity: 1,
          backgroundColor: "#333",
          borderRadius: 16,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        fontSize: "12px",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          textTransform: "none",
          padding: "12px 18px",
          fontWeight: 600,
        },
        contained: {
          background:
            "linear-gradient(90deg, #455EFF -26.75%, #B33BF6 41.2%, #FF5F14 108.26%)",
          color: "#fff",

          "&:hover": {
            color: "#ffffff",
            background: "#F9BAA7",
          },
          "&:active": {
            color: "#ffffff",
            background: "#F9BAA7",
          },
          "&:disabled": {
            color: "#474747",
            background: "#1F1F1F",
          },
        },
        secondary: {
          background: "#1F1F1F",
          color: "#F15223",

          "&:hover": {
            color: "#F9BAA7",
            background: "#FCDCD3",
          },
          "&:active": {
            color: "#F9BAA7",
            background: "#FCDCD3",
          },
          "&:disabled": {
            color: "#474747",
            background: "#1F1F1F",
          },
        },
        outlined: {
          background: "transparent",
          borderColor: "#F15223",
          color: "#F15223",

          "&:hover": {
            background: "transparent",
            color: "#F9BAA7",
            borderColor: "#F9BAA7",
          },
          "&:active": {
            background: "transparent",
            color: "#F9BAA7",
            borderColor: "#F9BAA7",
          },
          "&:disabled": {
            background: "transparent",
            color: "#474747",
            borderColor: "#1F1F1F",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: "#0A0A0A",
          borderRadius: "12px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&[type="number"]': {
            "-moz-appearance": "textfield",
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          },
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "filled" },
          style: {
            " .MuiInputLabel-root.Mui-focused, .MuiInputLabel-root": {
              color: "#7A7A7A",
            },

            "& .MuiFilledInput-root": {
              overflow: "hidden",
              backgroundColor: "#1F1F1F",
              border: "1px solid",
              borderColor: "#1F1F1F",
              borderRadius: "12px",
              fontWeight: 600,

              "&:placeholder": {
                color: "#7A7A7A",
              },

              "&:hover": {
                borderColor: "#999999",
              },
              "&.Mui-error": {
                borderColor: "red",
              },
              "& .MuiFilledInput-underline:after": {
                borderBottomStyle: "none",
              },
              "&.Mui-focused": {
                borderColor: "#fff",
              },
            },
          },
        },
        {
          props: { variant: "filled", color: "secondary" },
          style: {
            "& .MuiFilledInput-root": {
              backgroundColor: "#141414",
            },
          },
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          borderColor: "#999999",
          ".MuiFormLabel-root.Mui-focused, .MuiFormLabel-root": {
            color: "#7A7A7A",
          },
        },
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: "filled" },
          style: {
            border: "1px solid red",
            backgroundColor: "#1F1F1F",
            borderColor: "#1F1F1F",
            borderRadius: "12px",
            "&:hover": {
              borderColor: "#999999",
            },
            "&.Mui-focused": {
              borderColor: "#fff",
            },

            "&.MuiFilledInput-root:after, &.MuiFilledInput-root:before, &.MuiFilledInput-underline:after, &.MuiFilledInput-underline:before":
              {
                border: "none",
              },
          },
        },
        {
          props: { variant: "filled", color: "secondary" },
          style: {
            backgroundColor: "#141414",
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiMenu-paper": {
            borderRadius: "12px",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          background: "#1F1F1F",
          py: 0,
          borderRadius: "12px",
          border: "1px solid #333",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "14px 12px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontWeight: 600,
          color: "white",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: "red",
        },
      },
    },

  },
});

export default theme;
