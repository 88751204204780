import { Typography } from "@mui/material";

function Logo() {
  return (
    <Typography
      color="primary.main"
      fontWeight="800"
      textTransform="uppercase"
      sx={{
        fontStyle: "italic",

        fontSize: "28px",
      }}
    >
      PAY
      <Typography
        textTransform="uppercase"
        color="text.hint"
        component="span"
        fontWeight="800"
        sx={{ fontSize: "inherit" }}
      >
        2
      </Typography>
      PAY
    </Typography>
  );
}
export { Logo };
